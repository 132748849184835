import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const dictionary = {
  title1: {
    ru: "Есть вопросы?",
    en: "Have a question?"
  },
  title2: {
    ru: "Предлагаем вам ответы на вопросы, которые нам задают чаще всего.",
    en: "Search our FAQ for answers to anything you might ask."
  }
}

const Headline = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  return (
    <Box>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'textSecondary'}
        align={'center'}
      >
        F.A.Q.
      </Typography>
      <Typography
        variant="h2"
        align={'center'}
        fontWeight={700}
        gutterBottom
      >
        {dictionary.title1[lang]}
      </Typography>
      <Typography variant="h6" align={'center'} color={'textSecondary'}>
      {dictionary.title2[lang]}
      </Typography>
    </Box>
  );
};

export default Headline;
