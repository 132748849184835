import { CheckCircle, SentimentDissatisfied } from '@mui/icons-material';
import { Box, Typography, Button, Grid, Card } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



const webinarBlock = {
    en: <Card sx={{ mt: 4, p: 3, backgroundColor: "#0c133e", color: "#fff" }}>
        <Grid container>
            <Grid item md={8} xs={12}>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>UK Global Talent Visa: What You Need To Know</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 300, mt: 2 }}>If you are eager to get more expert insights into how Global Talent visas work, we recommend that
                    you sign up to our LIVE webinar. During the webinar, you will be able to direct all your questions.</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{ display: "flex", mt:{md:0, xs: 3} }}>
                    <EventNoteIcon sx={{ fontSize: 60, mr: 3 }} />
                    <Box>
                        <Typography sx={{ fontSize: "20px" }}>
                            Wednesday
                        </Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                            3:00 pm, London
                        </Typography>
                    </Box>

                </Box>

                <Button target="_blank" href="https://us06web.zoom.us/webinar/register/8916437246762/WN_uj9noDKGTfCJMIWNIuTIRw" variant="contained" sx={{ mt: 3, width: "100%" }}>
                    Sign Up <ArrowForwardIcon sx={{ ml: 2 }} />
                </Button>
            </Grid>
        </Grid>
    </Card>,
    ru: <Card sx={{ mt: 4, p: 3, backgroundColor: "#0c133e", color: "#fff" }}>
        <Grid container>
            <Grid item md={8} xs={12}>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>БРИТАНСКАЯ ВИЗА ТАЛАНТОВ: ВЕБИНАР</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 300, mt: 2 }}>Если Вы хотели бы узнать больше о британской визе талантов напрямую от иммиграционных экспертов, мы рекомендуем Вам записаться на наш вебинар. Вы сможете задать любые вопросы напрямую нашим опытным и высококвалифицированным иммиграционным юристам.
</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{ display: "flex", mt:{md:0, xs: 3} }}>
                    <EventNoteIcon sx={{ fontSize: 60, mr: 3 }} />
                    <Box>
                        <Typography sx={{ fontSize: "20px" }}>
                            Среда
                        </Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                            15:00, Лондон
                        </Typography>
                    </Box>

                </Box>

                <Button target="_blank" href="https://us06web.zoom.us/webinar/register/8916437246762/WN_uj9noDKGTfCJMIWNIuTIRw" variant="contained" sx={{ mt: 5, width: "100%" }}>
                    Регистрация <ArrowForwardIcon sx={{ ml: 2 }} />
                </Button>
            </Grid>
        </Grid>
    </Card>
}


const artResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>

                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography sx={{ my: "auto", fontSize: "18px" }}><CheckCircle color='success' sx={{ mx: 1}} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}


            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>
                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const techResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                    Мы пришлем Вам email с дополнительной информацией и ссылкой, по которой Вы сможете забронировать консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам email с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const archResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const socialResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.ru}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const scienceResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const filmResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const fashionResultTypes = {
    0: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible to apply for this route!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Есть возможность, что у Вас есть достаточно оснований для успешной подачи на британскую визу талантов!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Пожалуйста, поделитесь Вашим резюме, LinkedIn и любыми дополнительными документами по Вашему усмотрению!
                    Мы рассмотрим предоставленную Вами информацию и свяжемся с Вами в течение 24 часов
                </Typography>
                <Typography>
                    Вы также можете:
                    <ul>

                        <li>Забронировать часовую консультацию с одним из наших иммиграционных юристов за 99 фунтов.</li>
                        <li>Запросить подробный отчет по всем пунктам данного опроса за 125 фунтов.</li>
                    </ul>
                </Typography>
                {webinarBlock.ru}
            </Box>
        }
    },
    1: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Congratulations you may potentially be eligible for the Global Talent visa!</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    You will shortly receive an email from us detailing further information.
                </Typography>
                {webinarBlock.en}
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex", m: 'auto', mb: 2 }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><CheckCircle color='success' sx={{ mx: 1 }} /><b>Поздравляем! Возможно у Вас есть основания для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", m: 'auto' }}>
                    Мы пришлем Вам имейл с дополнительной информацией и ссылкой, по которой Вы сможете забронировать 15-ти минутную консультацию с одним из наших юристов.
                </Typography>
                {webinarBlock.ru}
            </Box>
        }

    },
    2: {
        body: {
            en: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>Unfortunately, you may not be eligible for this route just yet</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Please schedule a consultation with our immigration lawyers for 99 to discuss other potential routes such as skilled worker, tier 5 or innovator visa.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Schedule call
                </Button>
            </Box>,
            ru: <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ my: "auto" }}><SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" /> <b>К сожалению, на данный момент у Вас недостаточно оснований для успешной подачи на британскую визу талантов.</b></Typography>
                </Box>
                <Typography sx={{ textAlign: "left", mt: 2 }}>
                    Вы можете забронировать консультацию с одним из наших юристов за 99 фунтов, на которой Вам могут более подробно рассказать об альтернативных визах, включая tier 5, skilled worker и  innovator visas.
                </Typography>
                <Button
                    variant="contained"
                    target="_blank"
                    href="https://calendly.com/sterling-law/immigration-talent-visa"
                    sx={{
                        mt: 3
                    }}
                >
                    Забронировать звонок
                </Button>
            </Box>
        }
    }
}

const resultTypes = {
    tech: techResultTypes,
    art: artResultTypes,
    arch: archResultTypes,
    science: scienceResultTypes,
    social: socialResultTypes,
    film: filmResultTypes,
    fashion: fashionResultTypes
}

export default resultTypes;