let consts = {
    API_URL: process.env.REACT_APP_API_URL,
    dict: {
        gtv: {
            en: "Global Talent Visa",
            ru: "Виза талантов"
        }
    }
}

if (process.env.NODE_ENV === "development") {
    consts.API_URL = "http://127.0.0.1:4000"
} 

export default consts;