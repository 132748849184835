/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { AccessTime, Home, Work } from '@mui/icons-material';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const mock = [
  {
    title: {
      en: 'Make a home in the UK',
      ru: 'Сделайте Великобританию своим домом'
    },
    subtitle: {
      en: 'The Global Talent visa leads to settlement in the UK from just 3 years.',
      ru: 'Виза Талантов дает возможность получить постоянный вид на жительство в Великобритании всего через 3 года.'
    },
    icon: <Home/>,
  },
  {
    title: {
      en: 'Freedom to grow your career',
      ru: 'Свобода в построении карьеры'
    },
    subtitle: {
      en: 'You have the flexibility to change roles or employers, enter self-employment, set up a spin-out company and/or earn money from other sources such as consultancy without seeking permission from the Home Office.',
      ru: "Виза Талантов дает возможность менять работу и работодателей, стать индивидуальным предпринимателем, учредить дочернюю компанию и/или получать доход из других источников (к примеру, консалтинг) без необходимости получать разрешение у Home Office-а."
    },
    icon: <Work/>,
  },
  {
    title: {
      en: 'Fast endorsement process',
      ru: 'Быстрый процесс одобрения аккредитованной организацией'
    },
    subtitle: {
      en: 'For the fast-track endorsement routes, the endorsing bodies usually return a decision to the Home Office within 14 working days.',
      ru: 'По ускоренной процедуре одобрения, аккредитованная организация обычно информирует Home Office о своем решении в течение 14-ти рабочих дней.'
    },
    icon: <AccessTime/>,
  },
];

const ditcionary = {
  gtv: {
    en: "Global Talent Visa",
    ru: "Виза талантов"
  },
  subtitle: {
    en: "Design your road to the dream Global Talent Visa and Sterling Law",
    ru: "Найдите путь к своей мечте с Визой Талантов и Sterling Law",
  }
}

const Services = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {ditcionary.gtv[lang]}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={'center'}
          >
            {ditcionary.subtitle[lang]}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title[lang]}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle[lang]}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
