import { AddBox } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";


interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}


const techClarifications: Questions = {
    1: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>Please note the following specialisms are generally not considered suitable</p>
                    <ol>
                        <li>Service Delivery, Process Delivery, Outsourcing, Consultancy (technical or management), ERP Consultancy, Systems Admin and all related fields.</li>
                        <li>Corporate roles or experience of managing large corporate teams.</li>
                        <li>Junior investors/analysts. Such specialisms must be supported by an investment track record made at a senior level and are not suitable for Global Promise.</li>
                        <li>Business skills apply to in-house work within product-led digital technology companies, not tech-enabled or service companies such as agencies, outsourcers, marketing firms etc.</li>
                    </ol>
                    <p>Please note if your MAIN occupation is on the list, you may wish to
                        rely on your activities in a different category of jobs and proceed
                        with your application. Further advice will be provided at a later stage
                        of the assessment.</p>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Специальности, считающиеся не подходящими, включают в себя: предоставление услуг в работе с технологиями, выполнение операционных процессов, аутсорсинг, консалтинговая деятельность (по вопросам как технологического, так и управленческого характера), консалтинг по планированию ресурсов предприятия, системная администрация и связанные сферы деятельности.
                    Также не подходят корпоративные роли или опыт управления большими корпоративными штатами сотрудников, опыт младших инвесторов или аналитиков (такая специализация должна быть подкреплена инвестиционным портфолио высшего уровня, иначе она не подходит для подачи на Global Promise).
                    Деловые навыки должны происходить именно из опыта работы в компаниях информационных технологий, ориентированных на создание какого-либо технологического продукта. Они не могут происходить из работы в высокотехнологичных или обслуживающих компаниях, вроде агенств, аутсорсеров, маркетинговых компаний и т.д.
                </Typography>
            </Box>
        }
    },
    2: {
        options: {},
        clarification: {
            en: <Grid container>
                <Grid item md={6} xs={12} p={2}>
                    Please note the following list is not exhaustive.

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p><b>Technical Skills</b></p>
                        <ol>
                            <li>DevOps / SysOps engineers</li>
                            <li>Principal software engineers/developers</li>
                            <li>Experienced data scientists/data engineers</li>
                            <li>Artificial Intelligence, Natural Language</li>
                            <li>Processing and Machine Learning experts (AI, NLP, ML)</li>
                            <li>Cybersecurity experts</li>
                            <li>Hardware engineers</li>
                            <li>Experienced front-end developers</li>
                            <li>Operating systems engineers</li>
                            <li>Experienced video game developers</li>
                            <li>Experienced UX/UI designers</li>
                            <li>Experienced Mobile App developers</li>
                            <li>Experienced back end developers leading development of, or contributing heavily to major new technologies or open-source projects (e.g. blockchain, Scala, Golang, Elasticsearch etc)</li>
                            <li>CTO or VP engineering experience managing teams of in-house employees at a growing digital business</li>
                            <li>Virtual and augmented reality developers</li>
                        </ol>
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} p={2}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p><b>Business Skills</b></p>
                        <ol>
                            <li>Experience of leading substantial VC investment over £25m GBP</li>
                            <li>Experience as a commercial/business lead (P&L, growth, sales and distribution strategy) in a digital business</li>
                            <li>Experience of expanding or growing a significant product-led digital technology business Sector-specific experience e.g. payment infrastructure in FinTech / international expansion in EdTech etc.</li>
                            <li>Solution sales experts</li>
                            <li>Experienced Product Manager</li>
                            <li>SaaS or enterprise sales leadership for digital services</li>
                            <li>Solution sales skills performed for a growing B2B digital business (i.e. not big-company experience</li>
                            <li>Performance marketing experts, performed in house for digital businesses</li>
                            <li>Experienced and senior VC or PE analysts with track records of leading investments in digital businesses</li>
                            <li>Experience as C Suite in a SMEs + (CEO, CMO, CIO) or head of operations for a digital business</li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>,
            ru: <Grid container>
                <Grid item md={6} xs={12} p={2}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p><b>Technical Skills</b></p>
                        <ol>
                            <li>DevOps / SysOps engineers</li>
                            <li>Principal software engineers/developers</li>
                            <li>Experienced data scientists/data engineers</li>
                            <li>Artificial Intelligence, Natural Language</li>
                            <li>Processing and Machine Learning experts (AI, NLP, ML)</li>
                            <li>Cybersecurity experts</li>
                            <li>Hardware engineers</li>
                            <li>Experienced front-end developers</li>
                            <li>Operating systems engineers</li>
                            <li>Experienced video game developers</li>
                            <li>Experienced UX/UI designers</li>
                            <li>Experienced Mobile App developers</li>
                            <li>Experienced back end developers leading development of, or contributing heavily to major new technologies or open-source projects (e.g. blockchain, Scala, Golang, Elasticsearch etc)</li>
                            <li>CTO or VP engineering experience managing teams of in-house employees at a growing digital business</li>
                            <li>Virtual and augmented reality developers</li>
                        </ol>
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} p={2}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p><b>Business Skills</b></p>
                        <ol>
                            <li>Experience of leading substantial VC investment over £25m GBP</li>
                            <li>Experience as a commercial/business lead (P&L, growth, sales and distribution strategy) in a digital business</li>
                            <li>Experience of expanding or growing a significant product-led digital technology business Sector-specific experience e.g. payment infrastructure in FinTech / international expansion in EdTech etc.</li>
                            <li>Solution sales experts</li>
                            <li>Experienced Product Manager</li>
                            <li>SaaS or enterprise sales leadership for digital services</li>
                            <li>Solution sales skills performed for a growing B2B digital business (i.e. not big-company experience</li>
                            <li>Performance marketing experts, performed in house for digital businesses</li>
                            <li>Experienced and senior VC or PE analysts with track records of leading investments in digital businesses</li>
                            <li>Experience as C Suite in a SMEs + (CEO, CMO, CIO) or head of operations for a digital business</li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>,
        }
    },
    3: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>Tech nation does not provide specific requirement we interpret it to include total accumulated time of employment and self-employment terms.</p>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>Tech Nation (аккредитованная спонсорская организация) не предоставляет конкретных требований к опыту работы. Мы интерпретируем ответ на данный вопрос как общее накопленное время работы и самозанятости в сфере технологий.</p>
                </Typography>
            </Box>
        }
    },
    4: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    Such as growth of a digital technology company, product or team. Evidenced by reference letter(s) from leading industry expert(s) describing your work, or as evidenced by news clippings, lines of code from public repos or similar evidence.
                </Typography>
                <Typography id="modal-modal-description">
                    Business Development which has enabled substantial revenue and/or customer growth or major commercial success, as evidenced by reference letter(s) from leading industry expert(s) describing your work, senior global commercial executives inside the company and/or at company partners/customers, or similar evidence.
                </Typography>
            </Box>,
            ru: <Typography id="modal-modal-description">
                <p>«Лидер» в категориях ведущего и признанного талантов должен продемонстрировать исключительные способности благодаря стойкому или развивающемуся национальному или международному признанию. Вы должны быть способны продемонстрировать навыки (в том числе, развивающиеся навыки), которые поставят Вас в авангарде соответствующей области профессиональной деятельности в сфере информационных технологий.</p>
            </Typography>
        }
    },
    // 5: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             <p>Nationally refers to evidence recognized in your country of residence (one country)</p>
    //             <p>Internationally refers to evidence with international recognition and exposure</p>
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //             <p>Ответ «на национальном уровне» подразумевает наличие свидетельств о признании работы заявителя в стране проживания (и только в этой стране)</p>
    //             <p>Ответ «на международном уровне» подразумевает наличие свидетельств о признании работы заявителя на международном уровне</p>
    //         </Typography>
    //     }
    // },
    // 6: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">Evidenced by reference letter(s) from leading industry expert(s) describing your work, or as evidenced by news clippings, lines of code from public repos or similar evidence.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">Вам необходимо будет подтвердить Ваш ответ рекомендательными письмами от ведущих экспертов отрасли, с описанием Вашей работы 
    //         Иначе, Вы можете предоставить вырезки из новостей, описывающие Вашу работу, строки программного кода из открытых репозиториев и аналогичные доказательства            
    //         </Typography>
    //     }
    // },
    // 7: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             Development that has enabled substantial revenue and/or customer growth or major commercial success, as evidenced by reference letter(s) from leading industry expert(s) describing your work, senior global commercial executives inside the company and/or at company partners/customers, or similar evidence.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //             Развитие бизнеса для этой категории должно обеспечить значительное повышение дохода, рост базы клиентов или крупный коммерческий успех. Вам необходимо будет подтвердить это рекомендательными письмами от ведущих экспертов отрасли, с описанием Вашей работы. Например, такое письмо может быть от старших глобальных коммерческих руководителей внутри компании, а так же от партнеров/клиентов компании. Вы также можете предоставить аналогичные свидетельства.
    //         </Typography>
    //     }
    // },
    // 8: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             Evidenced by reference letter(s) from leading industry expert(s) describing your work, or as evidenced by news clippings or similar evidence.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //             Вам необходимо будет подтвердить Ваш ответ рекомендательными письмами от ведущих экспертов отрасли, с описанием Вашей работы.
    //             Иначе, Вы можете предоставить вырезки из новостей, описывающие Вашу работу, и аналогичные доказательства.
    //         </Typography>
    //     }
    // },
    5: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    Please not this refers to experience outside of your normal day-to-day job role. Evidence by contributions to an Open Source project, your GitHub profile demonstrating active participation in a collaborative project, your Stack Overflow profile showing significant contribution to discussions around code All contributions (for example to an Open-Source project, GitHub, or Stack Overflow profile etc.) must demonstrate a consistent and ongoing track record of contributions to the sector. Contributions which have been made solely to support the timing of your application are unacceptable.
                </Typography>
            </Box>,
            ru: <Typography id="modal-modal-description">
                Вам необходимо будет подтвердить Ваш ответ доказательствами в виде вклада в проекты с открытым исходным кодом, активного участия в коллаборативном проекте, которое можно увидеть на Вашем профиле GitHub, активного и значительного участия в дискуссиях по поводу исходного кода на Вашем профиле Stack Overflow.
                Каждый из приведенных примеров должен демонстрировать продолжающийся и постоянный характер участия в сфере развития технологий. Недопустимыми считаются вклады в развитие проектов и технологий сделанные исключительно для поддержки Вашей подачи на визу талантов.
            </Typography>
        }
    },
    6: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Please note the prizes and awards must be specifically in the digital technology sector. Example of prizes such as innovate UK grants, bursaries, scholarships, accelerator programs or equivalent that recognize your work as innovative. Examples of awards of excellence must be outside your employment such as bright tech future awards or equivalently recognised awards to your home country. Please note the above are only examples and are not an exhaustive list.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Примеры призов:
                <ul>
                    <li>инновационные гранты в Великобритании</li>
                    <li>стипендии (выданные по финансовым или академическим соображениям)</li>
                    <li>акселерационные программы и их эквиваленты, которые признают Вашу работу инновационной</li>
                </ul>
                <p>Награды должны быть получены за достижения, выходящие за рамки Ваших обычных рабочих обязанностей. К примеру, награда может быть за светлое технологическое будущее (или аналог данной награды в Вашей стране проживания)</p>
                <p>Обратите внимание, что все вышеперечисленное является лишь примером, а не исчерпывающим списком.</p>
            </Typography>
        }
    },
    7: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Example of prizes such as innovate UK grants, bursaries, scholarships, accelerator programs or equivalent that recognize your work as innovative. Please note the above are only examples and are not an exhaustive list.
                Examples of awards of excellence must be outside your employment such as bright tech future awards or equivalently recognised awards to your home country that you have received for outstanding applied work. Please note the above are only examples and are not an exhaustive list.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Примеры призов:
                <ul>
                    <li>инновационные гранты в Великобритании</li>
                    <li>стипендии (выданные по финансовым или академическим соображениям)</li>
                    <li>акселерационные программы и их эквиваленты, которые признают Вашу работу инновационной</li>
                </ul>
                <p>Награды должны быть получены за достижения, выходящие за рамки Ваших обычных рабочих обязанностей. К примеру, награда может быть за светлое технологическое будущее (или аналог данной награды в Вашей стране проживания)</p>
                <p>Обратите внимание, что все вышеперечисленное является лишь примером, а не исчерпывающим списком.</p>
            </Typography>
        }
    },
    // 12: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             Examples of awards of excellence must be outside your employment such as bright tech future awards or equivalently recognised awards to your home country that you have received for outstanding applied work. Please note the above are only examples and are not an exhaustive list.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //             <p>Награды должны быть получены за достижения, выходящие за рамки Ваших обычных рабочих обязанностей. К примеру, награда может быть за светлое будущее технологии (или аналог данной награды в Вашей стране проживания)</p>
    //             <p>Обратите внимание, что все вышеперечисленное является лишь примером, а не исчерпывающим списком.</p>
    //         </Typography>
    //     }
    // },
    8: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Nationally refers to prizes recognized in your country of residence (one country)</p>
                <p>Internationally refers to prizes with international recognition and exposure</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Ответ «на национальном уровне» подразумевает признание награды в стране проживания (и только в этой стране).</p>
                <p>Ответ «на международном уровне» подразумевает награды имеющие международное признание и известность.</p>
            </Typography>
        }
    },
    9: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Speaking at informative events dedicated to the digital technology sector such as conferences, meet ups, webinars, seminars, etc.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Выступления могут быть на конференциях, встречах, вебинарах, семинарах и т.д.
            </Typography>
        }
    },
    10: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Such as material in professional publications, major trade, and/or major media. You must include the title, date, and evidence that you are the author of such published material and any necessary translation.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Вы должны указать название, дату и предоставить доказательства, что Вы являетесь автором подобного материала (с необходимым переводом).
            </Typography>
        }
    },
    11: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    12: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Held or hold a significant expert role participating on panels, or individually, assessing the work of others in the same field or a field of specialisation related to the digital technology sector.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Играли ли Вы важную/экспертную роль в таком процессе и играете ли Вы ее до сих пор? Для удовлетворения этого критерия Вы должны выносить оценку работы других специалистов в сфере информационных технологий.
            </Typography>
        }
    },
    13: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                You can demonstrate this by providing evidence of any genuine and significant product-led digital technology businesses you have established as a founder or senior executive which is currently active or has been dissolved in the last five years.  Any company provided as evidence should demonstrate a level of income beyond solely covering the applicant’s salary and must have been commercially successful or otherwise demonstrate how the applicant meets the endorsement criteria.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Вы можете продемонстрировать такой опыт посредством предоставления свидетельств о существовании любых подлинных и значимых компаний по информационным технологиям, разрабатывающих продукт, если Вы играли в них роль основателя или старшего руководителя.</p>
                <p>Эти компании должны либо активно существовать и работать на данный момент или были быть распущены в течение последних пяти лет.</p>
                <p>Любая компания представленная в качестве доказательства должна будет продемонстрировать уровень дохода, превышающий размер зарплаты заявителя.</p>
                <p>Эта компания должна иметь коммерческий успех или иным образом продемонстрировать, что заявитель соответствует критериям Tech Nation на одобрение его заявки. </p>
            </Typography>
        }
    },
    // 19: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             You can demonstrate this by providing evidence of any genuine and significant product-led digital technology businesses you have established as a founder or senior executive which is currently active or has been dissolved in the last five years.  Any company provided as evidence should demonstrate a level of income beyond solely covering the applicant’s salary and must have been commercially successful or otherwise demonstrate how the applicant meets the endorsement criteria.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //         <p>Вы можете продемонстрировать такой опыт посредством предоставления свидетельств о существовании любых подлинных и значимых компаний по информационным технологиям, разрабатывающих продукт, если Вы играли в них роль основателя или старшего руководителя.</p>
    //         <p>Эти компании должны либо активно существовать и работать на данный момент или были быть распущены в течение последних пяти лет.</p>
    //         <p>Любая компания представленная в качестве доказательства должна будет продемонстрировать уровень дохода, превышающий размер зарплаты заявителя.</p>
    //         <p>Эта компания должна иметь коммерческий успех или иным образом продемонстрировать, что заявитель соответствует критериям Tech Nation на одобрение его заявки. </p>
    //     </Typography>
    //     }
    // },
    // 20: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             You can demonstrate this by providing evidence that you have gone beyond your day-to-day profession to engage in an activity that contributes to the advancement of the sector.  Examples may include mentoring, advising, organising interest groups, leading on policy, teaching at a university, or participating in clubs or societies for the furthering of the field.
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //         <p>Вы можете предоставить доказательства того, что Вы занялись деятельностью, не входящей в Ваши прямые рабочие обязанности, но тем не менее способствует развитию сферы информационных технологий.</p>
    //         <p>Примеры могут включать в себя наставничество, консультирование, создание группы по интересам, руководство по вопросам стратегии, преподавание в университете или участие в клубах и сообществах с целью развития данной области.</p>
    //     </Typography>
    //     }
    // },
    14: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Mentorship must be outside your organisation or normal course of work duties on behalf of a structured programme. You must be able to demonstrate a consistent track record of mentoring and have received recognition for your personal contribution. Mentoring at a university or a single startup is not sufficient. When mentoring alongside other mentors (for example at a top-tier accelerator) you must have been a senior mentor that may be evidenced through a letter of reference from the programme. Note that simply training a colleague, providing general support or advice, or advising at a company of someone you know is not considered to meet the definition of mentorship.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Вам необходимо продемонстрировать долгосрочный опыт наставничества и признание за свой личный вклад. Опыта наставничества в университете или в отдельном стартапе недостаточно для этой категории. При совместном наставничестве с другими (например, в акселераторе высшего уровня) Вы должны занимать должность старшего наставника. Это может быть подтверждено рекомендательным письмом от соответствующей программы. Обратите внимание, что обучение коллеги, общая поддержка или советы, а так же консультирование в компании, принадлежащей знакомому Вам человек, не соответствует толкованием понятия наставничество.
            </Typography>
        }
    },
    // 22: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             <p>The term ‘significant contribution’ in this criteria requires you to demonstrate impact, not necessarily innovation.</p>

    //             <p>Technical examples of a “significant contribution” include:</p>
    //             <ol>

    //                 <li>Having led in the development of high-impact digital products or services;</li>
    //                 <li>Starting or contributing to open source projects in a way that has been acknowledged by peers as advancing the field;</li>
    //                 <li>Having worked as a key engineer in the core product of a start-up, showing evidence as to how you have contributed to its success.</li>
    //             </ol>
    //             <p>Business examples of a “significant contribution” include:</p>
    //             <ol>
    //                 <li>Having led or played a key role in the growth of a product-led digital technology company, such as influencing investment and strategy or delivering major products or releases;</li>
    //                 <li>Having founded a successful product-led digital technology company or other organisation and leading it.</li>
    //             </ol>
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //             <p>«Значительный вклад» означает, что Вам необходимо продемонстрировать, что Вы оказали влияние на индустрию. Не обязательно демонстрировать новаторство.</p>

    //             <p>Примеры «значительного вклада» в сфере Технологий:</p>
    //             <ol>

    //                 <li>руководство разработкой высокоэффективных технологических продуктов или услуг</li>
    //                 <li>создание или участие в проектах с открытым исходным кодом и признание этой активности  коллегами как развитие области информационных технологий</li>
    //                 <li>работа в качестве ключевого инженера над основным продуктом разработки стартапа и демонстрация доказательств тому, что Вы внесли непосредственный вклад в его успех</li>
    //             </ol>
    //             <p>Пример «значительного вклада» в сфере Бизнеса:</p>
    //             <ol>
    //                 <li>руководство или ключевая роль в росте компании, специализирующейся на разработке продукта информационных технологий (например, оказание влияния на инвестиции или стратегию, или же обеспечение основных продуктов или выпусков)</li>
    //                 <li>создание успешной компании, специализирующейся на разработке продукта информационных технологий или другую организацию, а также ее возглавление</li>
    //             </ol>
    //         </Typography>
    //     }
    // },
    // 23: {
    //     options: {},
    //     clarification: {
    //         en: <Typography id="modal-modal-description">
    //             <p>The term ‘significant contribution’ in this criteria requires you to demonstrate impact, not necessarily innovation.</p>

    //             <p>Technical examples of a “significant contribution” include:</p>

    //             <ol>
    //                 <li>Having led in the development of high-impact digital products or services;</li>
    //                 <li>Starting or contributing to open source projects in a way that has been acknowledged by peers as advancing the field;</li>
    //                 <li>Having worked as a key engineer in the core product of a start-up, showing evidence as to how you have contributed to its success.</li>
    //             </ol>
    //             <p>Business examples of a “significant contribution” include:</p>
    //             <ol>
    //                 <li>Having led or played a key role in the growth of a product-led digital technology company, such as influencing investment and strategy or delivering major products or releases;</li>
    //                 <li>Having founded a successful product-led digital technology company or other organisation and leading it.</li>
    //             </ol>
    //         </Typography>,
    //         ru: <Typography id="modal-modal-description">
    //         <p>«Значительный вклад» означает, что Вам необходимо продемонстрировать, что Вы оказали влияние на индустрию. Не обязательно демонстрировать новаторство.</p>

    //         <p>Примеры «значительного вклада» в сфере Технологий:</p>
    //         <ol>

    //             <li>руководство разработкой высокоэффективных технологических продуктов или услуг</li>
    //             <li>создание или участие в проектах с открытым исходным кодом и признание этой активности  коллегами как развитие области информационных технологий</li>
    //             <li>работа в качестве ключевого инженера над основным продуктом разработки стартапа и демонстрация доказательств тому, что Вы внесли непосредственный вклад в его успех</li>
    //         </ol>
    //         <p>Пример «значительного вклада» в сфере Бизнеса:</p>
    //         <ol>
    //             <li>руководство или ключевая роль в росте компании, специализирующейся на разработке продукта информационных технологий (например, оказание влияния на инвестиции или стратегию, или же обеспечение основных продуктов или выпусков)</li>
    //             <li>создание успешной компании, специализирующейся на разработке продукта информационных технологий или другую организацию, а также ее возглавление</li>
    //         </ol>
    //     </Typography>
    //     }
    // },
    15: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Academic research must be endorsed by a recognised academic expert either through peer review in a recognised publication or endorsement from a leading senior academic who has supervised the individual directly. Research undertaken as part of an undergraduate or MSc thesis does not qualify for this criteria.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Академические исследования должны быть одобрены признанным экспертом или же путем рецензирования в признанной публикации, либо одобрение может быть получено от ведущего старшего научного сотрудника, который был непосредственным руководителем автора публикации.</p>
                <p>Исследования не будут учтены в этой категории если они проводились в рамках диссертации на уровне бакалавриата или магистратуры.</p>
            </Typography>
        }
    },

}


export default techClarifications;