/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const mock = [
  {
    title: {
      en: 'Take our free online assessment at your convenience',
      ru: 'Пройдите бесплатный тест на нашем сайте'
    },
    subtitle: {
      en: 'All you need to do is answer a few questions about yourself and your field of expertise on our website in your own time to assess your chances of successfully applying for Global Talent visa endorsement',
      ru: 'Ответьте на несколько вопросов о себе и вашей профессиональной деятельности, чтобы получить примерную оценку ваших шансов получить визу талантов'
    },
    icon: <b>1</b>,
  },
  {
    title: {
      en: 'Our immigration lawyers will evaluate your responses',
      ru: 'Наши юристы оценят ваш результат'
    },
    subtitle: {
      en: 'Once you finish the questionnaire, if your score indicates that you may be eligible for Global Talent endorsement, we may request a CV from you or a link to your LinkedIn profile to assess your chances for a successful application in more detail. Should your responses suggest that your chances of successfully obtaining Global Talent endorsement are not strong enough yet, we can offer you a consultation about other possible immigration routes.',
      ru: "После того как вы получите результат теста, наши юристы могут запросить у Вас резюме или ссылку на Ваш LinkedIn профиль, чтобы провести более детальную оценку. В случае если они посчитают, что ваши шансы пока что недостаточно высоки, мы можем предложить вам консультацию о других возможных путях иммиграции в Великобританию."
    },
    icon: <b>2</b>,
  },
  {
    title: {
      en: 'You can book a consultation with one of our immigration advisors',
      ru: 'Запишитесь на консультацию к одному из наших иммиграционных юристов'
    },
    subtitle: {
      en: 'They will be able to answer your questions regarding the Global Talent application and your personal situation, as well as provide advice',
      ru: 'Они ответят на все ваши вопросы по поводу визу талантов и вашей ситуации.'
    },
    icon: <b>3</b>,
  },
  {
    title: {
      en: 'We will assist you with your application',
      ru: 'Мы поможем вам в подаче на визу талантов'
    },
    subtitle: {
      en: 'All you need to do is answer a few questions about yourself and your field of expertise on our website in your own time to assess your chances of successfully applying for Global Talent visa endorsement',
      ru: 'Если вы решите проинструктировать нас и воспользоваться нашими услугами в подаче на визу талантов, наши юристы помогут Вам на обоих этапах подачи'
    },
    icon: <b>4</b>,
  },
  {
    title: {
      en: 'Prepare for success',
      ru: 'Подготовьтесь к успеху'
    },
    subtitle: {
      en: 'Once the decision on your application is received, we will inform you of the outcome. Although we will do everything to ensure that the decision is positive, we are not the decision-maker; should you receive a refusal, we will either return to you the money for instructing us, or we can help with a review of that decision.',
      ru: "Как только решение по Вашей заявке будет получено, мы сообщим Вам результат. Мы сделаем все для того, чтобы решение было положительным, последнее слово остается за Home Office. В случае отказа, мы можем либо вернуть Вам деньги, либо помочь Вам добиться пересмотра решения."
    },
    icon: <b>5</b>,
  }
];


const ditcionary = {
  gtv: {
    en: "How it works?",
    ru: "Как это работает?"
  },
  subtitle1: {
    ru: `Данная платформа поможет оценить Ваши шансы на успешную подачу на визу талантов
    Если у Вас хорошие шансы, наша платформа также поможет Вам с подачей на одобрение
    заявки на визу талантов и на саму визу`,
    en: `This platform will help you assess your eligibility for Global Talent endorsement
    Once such eligibility is confirmed, it will also assist you in your application for both the endorsement
    and the Global Talent visa`
  }
}

interface FaqGroupItemProps {
  items: Array<{
    title: {
      en: string,
      ru: string
    };
    subtitle: {
      en: string,
      ru: string
    },
    icon: JSX.Element;
  }>;
}

const FaqGroupItem = ({ items }: FaqGroupItemProps): JSX.Element => {
  //@ts-ignore
  const { lang } = useContext(LangugaeContext);
  const theme = useTheme();
  return (
    <Box  data-aos="fade-up">

      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            borderRadius={`${theme.spacing(1)} !important`}
            marginBottom={2}

            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{ display: 'flex' }}
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Box
                component={Avatar}
                width={60}
                height={60}
               
                bgcolor={alpha(theme.palette.primary.main, 0.1)}
                color={theme.palette.primary.main}
              >
                {item.icon}
              </Box>
              <Typography sx={{my: 'auto', ml: 2}} fontWeight={600}> {item.title[lang]}</Typography>
            </Box>
            <AccordionDetails sx={{px: 4, pb: 3}}>


              <Typography sx={{ textAlign: 'justify' }} color="text.secondary">{item.subtitle[lang]}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};


const HowItWorks = (): JSX.Element => {
  //@ts-ignore
  const { lang } = useContext(LangugaeContext);
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"

            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {ditcionary.gtv[lang]}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{textAlign: "justify", lineHeight: "23px", mt: 1}}
            color="text.secondary"
            gutterBottom
          >
            
            {ditcionary.subtitle1[lang]}
          </Typography>
        </Box>
      </Box>
      <FaqGroupItem items={mock} />
    </Box>
  );
};

export default HowItWorks;
