/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const mock = [
  {
    img: "http://sterling-law.co.uk/wp-content/uploads/2021/07/sra.jpg"
  },
  {
    img: "http://sterling-law.co.uk/wp-content/uploads/2021/07/the-legal-500-logo.png"
  },
  {
    img: "http://sterling-law.co.uk/wp-content/uploads/2021/07/accreditation-immigration-law-colour.gif"
  },
  {
    img: "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/organisation/logo/263/OISC_RGB_Small_Full_Logo.jpg"
  }
];

const dictionary = {
  licence: {
    en: "Licence, accreditation & awards",
    ru: "Лицензии"
  }
}

const Licence = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box sx={{ color: "white", pb: 5 }} bgcolor={'#0c133e'}>
      <Container paddingY={{ xs: 4, sm: 6, md: '0 !important' }}>
        <Box marginBottom={4}>
          <Typography
            component={'span'}
            variant="h4"
            data-aos={'fade-up'}
            sx={{ fontWeight: 700, color: 'common.white' }}
          >
            {dictionary.licence[lang]}
          </Typography>
        </Box>
        <Box>

          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} md={1}></Grid> */}
            {mock.map((item, i) => (


              <Grid sx={{ display: 'flex' }} item xs={12} sm={6} md={3} key={i}>
                <Card data-aos={'fade-up'} sx={{
                  py: 3,
                  px: 2,
                  maxHeight: { xs: 233, md: 167 },
                  display: "flex"
                }}>
                  <Box
                    component="img"
                    sx={{
                      m: "auto",
                      width: "100%",

                    }}
                    src={item.img}
                  />
                </Card>


              </Grid>

            ))}
            {/* <Grid item xs={12} sm={12} md={1}></Grid> */}
          </Grid>

        </Box>
      </Container>
    </Box>
  );
};

export default Licence;
