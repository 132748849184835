import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsx } from '@emotion/react';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

interface FaqGroupItemProps {
  items: Array<{
    title: {
      en: string,
      ru: string
    };
    subtitle: {
      en: JSX.Element,
      ru: JSX.Element
    };
  }>;
}

const FaqGroupItem = ({ items }: FaqGroupItemProps): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography
          fontWeight={700}
          variant={'h5'}
        >
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title[lang]}</Typography>
            </Box>
            <AccordionDetails>
              <Typography sx={{textAlign: 'justify'}} color="text.secondary">{item.subtitle[lang]}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Content = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);

  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          items={[
            {
              title: {
                en: 'What is the Global Talent visa?',
                ru: 'Что такое Виза Талантов?'
              },
              subtitle: {
                en: <Box>
                  <Typography>
                    The Global Talent visa is a UK immigration category for talented and promising individuals in specific sectors wishing to work in the UK.
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    To be considered for entry under the Global Talent visa, applicants must normally seek endorsement from one of six endorsing bodies engaged by the UK Home Office.
                    The Global Talent visa is not a ‘sponsored’ visa and so it gives you maximum flexibility and freedom to live and work in the UK with no constraints along with the option to settle in the UK from just <b>3 years</b>.
                  </Typography>

                </Box>,
                ru: <Box>
                  <Typography>
                    Виза Талантов – это британская иммиграционная категория для талантливых и перспективных личностей, работающих в определенных сферах, которые хотят работать в Великобритании
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Для рассмотрения подачи на визу Global Talent, заявители обычно должны получить одобрение от одного из шести органов, уполномоченных Министерством Внутренних Дел Великобритании. Виза Талантов не требует спонсорства, а потому дает максимальную свободу и гибкость жить и работать в Великобритании без каких-либо ограничений, а также возможность получить постоянный статус в Великобритании через <b>3 года</b>.
                  </Typography>

                </Box>
              }

            },
            {
              title: {
                en: 'Do I need a UK visa if I am from the European Union (EU), Switzerland, Norway, Iceland or Liechtenstein?',
                ru: 'Нужна ли мне британская виза, если я имею паспорт Европейского Союза (ЕС), Швейцарии, Норвегии, Исландии или Лихтенштейна?'
              },
              subtitle: {
                en: <Typography>Yes. Freedom of movement for EU, Swiss and EEA citizens coming to work or study in the UK ended on 1 January 2021. This means that all non-UK nationals, except for Irish citizens, are now subject to UK visas and immigration rules.</Typography>,
                ru: <Typography>Да. Свободное передвижение для граждан ЕС, Швейцарии и Европейской Экономической Зоны для работы и учебы в Великобритании закончилось 1 января 2021. Это значит, что все люди, не имеющие британского гражданство (за исключением граждан республики Ирландия) теперь обязаны соблюдать британский визовый режим.</Typography>,
              }

            },
            {
              title: {
                en: 'Is there any English language requirement?',
                ru: 'Есть ли у визы талантов требование на знание английского языка?'
              },
              subtitle: {
                en: <Typography>No, there is no language requirement for this Visa.</Typography>,
                ru: <Typography>Нет, у визы талантов нет такого требования.</Typography>
              }
            },
            {
              title: {
                en: 'Is there any Minimum Financial Requirements?',
                ru: 'Если ли у визы талантов финансовые требования?'
              },
              subtitle: {
                en: <Typography>No, there is no minimum financial requirement for this Visa.</Typography>,
                ru: <Typography>Нет, у визы талантов нет такого требования.</Typography>,
              }

            },
            {
              title: {
                en: 'How do I know whether to apply under ’exceptional talent’ or ‘exceptional promise’?',
                ru: 'Как понять, нужно ли мне подавать на визу талантов как талантливый или как перспективный заявитель? (Exceptional Talent или Exceptional Promise)'
              },
              subtitle: {
                en: <Box>
                  <Typography>
                    This will be mostly influenced by your experience, the length of your employment, and what your recommendations have to say about you. There are no hard rules designating whether you are best suited to ‘talent’ or ‘promise.’
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    ‘Promise’ applicants are likely to be in the earlier stages in their career, with roughly 5 years or less relevant experience and are more likely to be able to demonstrate that they have the potential to be a leader but have yet to develop a proven track record.
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    If you have more than 5 years’ experience you are more likely to apply under ‘talent’ unless there has been a recent and significant shift in the direction of your career.
                  </Typography>
                </Box>,
                ru: <Box>
                  <Typography>
                    Это будет зависеть от Вашего опыта, как долго Вы уже работаете в Вашей сфере деятельности и какие у Вас имеются рекомендационные письма. Нет жестких правил диктующих категорию, в которой Вам необходимо подаваться.
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Перспективные заявители чаще находятся в начале своей карьеры, имея пять или менее лет опыта работы. Им легче показать, что они имеют потенциал лидерства, но им нужно время, чтобы накопить нужный опыт.
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Если у Вас больше пяти лет опыта, скорее всего Вы сможете податься как талантливый заявитель, в случае если Вы не меняли недавно свою сферу деятельности.
                  </Typography>
                </Box>
              }

              ,
            },
            {
              title: {
                en: 'How do I apply for endorsement?',
                ru: 'Как подать на одобрение уполномоченной организацией?'
              },
              subtitle: {
                en: <Typography>You first need to complete an <a target="_blank" href="https://www.gov.uk/global-talent-digital-technology/apply-for-endorsement">online Home Office application form</a>, which requires you to provide basic personal information. You need to submit this online and pay the £456 endorsement application fee. Once you have done this you will receive a unique application reference number (UAN) and a payment reference number. Within 15 working days of submitting the online Home Office application form you must submit an online application of your relevant endorsing body and you will need to provide your reference numbers in order to do so. Once all is submitted you will receive a decision on your visa between 6 to 8 weeks.</Typography>,
                ru: <Typography>Сначала Вам нужно заполнить онлайн <a target="_blank" href="https://www.gov.uk/global-talent-digital-technology/apply-for-endorsement">форму для Министерства Внутренних Дел Великобритании</a>, которая спросит у Вас основную информацию о Вас. Вам нужно будет заплатить за нее 456 фунтов стерлингов. После, Вам будет выдан уникальный референсный номер Вашей подачи (UAN) и референсный номер оплаты. В течение 15-ти рабочих дней с момента заполнения онлайн формы, Вы должны будете заполнить онлайн форму для уполномоченной организации и указать в ней Ваши референсные номера. Когда Вы отправите эту форму, Вы можете ожидать решения через 6-8 недель.</Typography>
              }
                
            },
            {
              title: {
                en: 'How long will my Visa be valid for?',
                ru: 'Как надолго мне выдадут визу?'
              },
              subtitle: {
                en: <Typography>When you come to make the stage 2 visa application you will be able to choose how much leave you apply for up to a maximum of 5 years. How long you apply for will be guided by whether you think you will be able to meet the requirements for settlement quickly. The number of years you will apply for will impact how much you have to pay in terms of the Immigration Health Surcharge, which currently costs £624 per year.</Typography>,
                ru:  <Typography>Когда Вы будете заполнять заявку на второй ступени процесса подачи, Вы сможете выбрать промежуток времени, который покроет Ваша виза (максимум 5 лет). Какой срок Вам выбрать будет зависеть от того, насколько быстро Вы можете удовлетворить критерии для постоянного вида на жительство в Великобритании. Выбранный Вами срок определит стоимость подачи на визу, так как Вы должны будете заплатить Immigration Health Surcharge, который стоит 624 фунта за год.</Typography>,
              } 
            },
            {
              title: {
                en: 'Can I bring my family with me to the UK on a Global Talent Visa?',
                ru: 'Может ли моя семья приехать со мной, если я получу визу талантов?',
              },
              subtitle: {
                en: <Typography>The UK Global Talent visa enables the applicant to take their family members with them. A dependent can be your spouse/wife, civil or unmarried partner and your children under 18.</Typography>,
                ru: <Typography>Виза талантов позволяет заявителю привезти с собой членов своей семьи, а конкретно супруга, гражданского супруга и детей до 18-ти лет</Typography>
              }
                
            },
            {
              title: {
                en: 'How long do I need to spend in the UK in order to settle in the Global Talent category?',
                ru: 'Как долго мне нужно находиться в Великобритании, чтобы получить постоянный вид на жительство по визе талантов?'
              },
              subtitle: {
                en: <Box>
                <Typography>
                  If you have been endorsed on the basis of ‘talent’ you need to be resident in the UK for three years and if endorsed under ‘promise’ the qualifying residence period is five years. However, you do not need to spend all of this time in the Global Talent category and can combine any time you have spent with leave in the following categories:
                </Typography>
                <ul>
                  <li>Innovator</li>
                  <li>Tier 1 (excluding Tier 1 (Graduate Entrepreneur))</li>
                  <li>Tier 2 (excluding Tier 2 (Intra-Company Transfer))</li>
                  <li>Skilled Worker</li>
                </ul>
              </Box>,
                ru: <Box>
                <Typography>
                Если Вы получаете визу талантов как талантливый заявитель, Вам нужно будет прожить в Великобритании три года. Если Вы получаете визу талантов как  перспективный заявитель, Вам нужно будет прожить в Великобритании пять лет. Вам не нужно проводить весь этот период только на визе талантов, так как вы можете удовлетворить этот срок комбинируя разные визы, если Вы ранее находились в Великобритании как:
                </Typography>
                <ul>
                  <li>Инноватор</li>
                  <li>Tier 1 (за исключением Graduate Entrepreneur)</li>
                  <li>Tier 2 (за исключением Intra-Company Transfer)</li>
                  <li>Skilled Worker</li>
                </ul>
              </Box>
              }
              
            },
            {
              title: {
                en: 'What should I do if my Global Talent Endorsement is refused?',
                ru: 'Что делать, если уполномоченная организация отказала мне?'
              },  
              subtitle: {
                en: <Typography>Under UK Immigration Rules, you cannot submit an appeal if your endorsement application is refused, however you can request Endorsement Review. You can request for review only once, but this will not prohibit you from reapplying. At Sterling Law, we are specialists at turning over refusals on endorsements. Our team will take the time to carefully evaluate the reasons why your endorsement application was refused and guide you on the best action to take.</Typography>,
                ru: <Typography>По иммиграционным правилам Великобритании, Вы не можете подать на апелляцию в случае отказа в этой категории, но Вы можете попросить пересмотреть решение. Вы можете сделать такой запрос только один раз, но после этого Вы будете иметь право переподаться на визу талантов. В Sterling Law, мы имеем большой опыт успешных пересмотров в случае отказа. Наша команда внимательно изучит причины, по которым Ваша заявка была отклонена и посоветует Вам, какие шаги лучше всего предпринять.</Typography>,
              }
                
            },
            {
              title: {
                en: 'What should I expect from the consultation?',
                ru: 'Что ожидать от консультации?'
              },
              subtitle: {
                en: <Typography>Once you have taken our free Global Talent online assessment, one of our immigration lawyers will look at the results and your CV/LinkedIn profile to make a more precise and holistic evaluation of your case. Should they confirm your eligibility for the Global Talent endorsement and you decide to instruct Sterling Law with your application, our immigration lawyers will assist you with every aspect of your application right up until the point of submission. If our immigration lawyers conclude that you have high chances for a successful Global Talent endorsement application and nonetheless you receive a refusal, we will return the fee you have paid for instructing us.</Typography>,
                ru: <Typography>После того как Вы пройдете бесплатный онлайн тест на нашем сайте, наши иммиграционные юристы рассмотрят Ваши результат и Ваше резюме/LinkedIn профиль, чтобы дать более точную оценку. Если они подтвердят, что у Вас высокие шансы на успешную подачу, и Вы решите проинструктировать Sterling Law помочь Вам с заявлением, наши юристы помогут Вам со всеми вопросами, связанными с визой талантов, вплоть до момента подачи. Если наши юристы придут к выводу, что у Вас высокие шансы на успешное рассмотрение заявки и, тем не менее, Вы получите отказ, мы вернём Вам оплату за наши услуги.</Typography>
              }
            }
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
