/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';
import { Grid, Card, CardActions, Button, Divider } from '@mui/material';





const dictionary = {
  gtv: {
    en: "Pricing",
    ru: "Стоимость услуг"
  },
  subtitle1: {
    ru: `Мы рады предложить Вам наши услуги по привлекательной цене!`,
    en: `We are proud to offer very competitive fees for Global Talent applications!`
  },
  price1: {
    ru: "£3500",
    en: "£3500"
  },
  price2: {
    ru: "£6800",
    en: "£6800"
  },
  name1: {
    en: "Standard service",
    ru: "Standard сервис"
  },
  name2: {
    en: "Premium service",
    ru: "Premium сервис"
  },
  des2: {
    en: `We will provide you with additional help, whereby you will only
    need to provide documents and relevant information to us and we will
    take care of everything else.`,
    ru: `Мы окажем Вам дополнительную помощь, так что все, что будет необходимо
    от Вас – это предоставить документы и необходимую информацию, а мы позаботимся
    об остальном.`
  },
  des1: {
    en: `Document review and assembly in preparation for the application.`,
    ru: `Помощь с подготовкой документов и их проверка перед подачей.`
  },
  t1: {
    en: `We also have No win no fee arrangements available!`,
    ru: `В некоторых случаях мы можем составить договор на возврат денег за наши услуги в случае
    отказа.`
  },
  t2: {
    en: `Please note that the Home Office fee and the IHS are separate and will need to be paid
    directly, hence will not form part of the no win no fee arrangement.`,
    ru: `Пожалуйста, учтите, что Home Office fee и IHS оплачиваются отдельно, а потому возврату не
    подлежат.`
  }
}




const Prices = (): JSX.Element => {
  
  //@ts-ignore
  const { lang } = useContext(LangugaeContext);
  return (
    <Box>

      {/* Our fees are:
      1. Standard service: £1,500 (document review and assembly in preparation for the
      application)
      2. Hand-holding service: £2,500 - £3,500, depending on the amount of work (we will
      provide you with additional help, whereby you will only need to provide documents and
      relevant information to us and we will take care of everything else)
      We also have No win no fee arrangements available!

      Please note that the Home Office fee and the IHS are separate and will need to be paid
      directly, hence will not form part of the no win no fee arrangement

      -


      Наши расценки составляют:
      1. Стандартный сервис - £1500 + НДС (помощь с подготовкой документов и их проверка
      перед подачей)
      2. Полная поддержка – £2500-£3500, в зависимости от количества работы (мы окажем
      Вам дополнительную помощь, так что все, что будет необходимо от Вас – это
      предоставить документы и необходимую информацию, а мы позаботимся об
      остальном)
      В некоторых случаях мы можем составить договор на возврат денег за наши услуги в случае
      отказа.
      Пожалуйста, учтите, что Home Office fee и IHS оплачиваются отдельно, а потому возврату не
      подлежат. */}
      <Box marginBottom={4}  data-aos="fade-up">
        <Box marginBottom={2}>
          <Typography
            variant="h4"


            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {dictionary.gtv[lang]}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{ textAlign: "justify", lineHeight: "23px", mt: 1 }}
            color="text.secondary"
            gutterBottom
          >
            {dictionary.subtitle1[lang]}
          </Typography>
          <Grid container sx={{mt: 3}}>

            <Grid md xs={12}>
              <Box  display={'flex'}>
                <Typography marginTop={"auto"} variant={'h5'} fontWeight={600} gutterBottom>
                {dictionary.name1[lang]}
                </Typography>
                <Box
                display={'flex'}
                alignItems={'baseline'}
                
                marginLeft={'auto'}
              >
                <Typography variant={'h3'} fontWeight={700}>
                  {dictionary.price1[lang]}
                </Typography>
                
              </Box>
              </Box>
              
              <Typography  variant="h6"
            component="p" sx={{ textAlign: "justify", lineHeight: "23px", mt: 1 }}>
                  {dictionary.des1[lang]}
                </Typography>
            </Grid>
            <Divider sx={{background: 'white', display: {md: "block", xs: "none"}, mx: 3}} orientation="vertical" color={'common.white'} flexItem/>
            <Divider sx={{display: {md: "none", xs: "block"}, background: 'white', width: '100%', my: 3}} flexItem/>
            <Grid md xs={12}>
              <Box display={'flex'}>
                <Typography marginTop={"auto"} variant={'h5'} fontWeight={600} gutterBottom>
                  {dictionary.name2[lang]}
                </Typography>
                <Box
                display={'flex'}
                alignItems={'baseline'}
                
                marginLeft={'auto'}
              >
                
                <Typography variant={'h3'} textAlign={'right'} fontWeight={700}>
                  {dictionary.price2[lang]}
                </Typography>
               
              </Box>
              </Box>
              
              <Typography  variant="h6"
            component="p" sx={{ textAlign: "justify", lineHeight: "23px", mt: 1 }}>
                  {dictionary.des2[lang]}
                </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            component="p"
            sx={{ textAlign: "justify", lineHeight: "23px", mt: 5 }}
            color="text.secondary"
            gutterBottom
          >
              {dictionary.t1[lang]}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{ textAlign: "justify", lineHeight: "23px", mt: 3 }}
            color="text.secondary"
            gutterBottom
          >
            *<i>{dictionary.t2[lang]}</i>
          </Typography>
          
        </Box>
      </Box>

    </Box>
  );
};

export default Prices;
