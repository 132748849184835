function calculatePoints(answers, questions) {
    let points = 0;
    let structuredAnswers = {};
    for (const key of Object.keys(questions)) {
        let question = questions[key];
        
        let answer = answers[question.id]?.split("_")[0]
        points += answer ? questions[key].options[answer]?.points : 0
        structuredAnswers[question.id] = answer;
    }
    return {
        answers: structuredAnswers,
        points: points
    };
}

module.exports = calculatePoints;
