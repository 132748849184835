/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Person } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';
import Container from 'components/Container';


const dictionary = {
  title: {
    en: "Why Sterling Law",
    ru: "Почему Sterling Law?"

  },
  subtitle1: {
    en: "We have handled many similar applications and we are proud of our extensive experience in immigration.",
    ru: "Мы – современная и инновационная юридическая компания с гибким подходом и установкой на успех."
  },
  subtitle2: {
    ru: <Typography
      variant="h6"
      component="p"
      sx={{ color: 'common.white', mt: 1, textAlign: "justify", lineHeight: "23px" }}
    >
      Наша специализация в нескольких областях права позволяет нам предлагать нашим клиентам, как отдельным лицам, так и компаниям, решения различных ситуаций. Это позволяет нам решать проблемы любой сложности, в которых пересекаются различные правовые направления.
    </Typography>,
    en: <Typography
      variant="h6"
      component="p"
      sx={{ color: 'common.white', mt: 1, textAlign: "justify", lineHeight: "23px" }}
    >
      Our expertise was acknowledged by Legal 500 rankings in the 2022 edition in which we featured. Please use the <Link href="https://www.legal500.com/firms/232697-sterling-law/232520-london-england/#section-112375">link</Link>
    </Typography>
  }
};

const About = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box bgcolor={'#0c133e'}>
      <Container paddingY={{ xs: 4, sm: 6, md: '0 !important' }}>
        <Grid container spacing={isMd ? 0 : 2}>
          <Grid
            item
            container
            alignItems={'center'}
            xs={12}
            md={6}
            data-aos="fade-up"
          >
            <Container paddingLeft={'0 !important'} sx={{pr: 5}}>
              <Box>
                <Box marginBottom={2}>
                  <Typography
                    component={'span'}
                    variant="h4"
                    sx={{ fontWeight: 700, color: 'common.white' }}
                  >
                    {dictionary.title[lang]}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ color: 'common.white', textAlign: "justify", lineHeight: "23px"}}
                >
                  {dictionary.subtitle1[lang]}
                </Typography>
                {dictionary.subtitle2[lang]}
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width={1}
              data-aos={'fade-up'}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              borderRadius={0}
              sx={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box marginBottom={1}>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <Box key={item} color={theme.palette.secondary.main}>
                        <svg
                          width={18}
                          height={18}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Typography color="text.primary">
                  Highly recommend Sterling Law, was very helpful for our Global talent and dependent visas. Would be happy to contact Sterling Law again during our moving to London!
                </Typography>
              </CardContent>
              <CardActions sx={{ paddingBottom: 2 }}>
                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  <ListItemAvatar>
                    <Box
                      component={Avatar}
                      width={40}
                      height={40}
                      marginBottom={0}
                      bgcolor={alpha(theme.palette.primary.main, 0.1)}
                      color={theme.palette.primary.main}
                    >
                      <Person />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={'Elena'}

                  />
                </ListItem>
              </CardActions>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
