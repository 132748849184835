import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { ContactCard, Content } from './components';

const CompanyTerms = (): JSX.Element => {
  const theme = useTheme();
  document.title = "Company Terms | Sterling Law"
  return (

    <Container>
      <Box boxShadow={4} borderRadius={2}>
        <Box bgcolor={theme.palette.primary.main} borderRadius={2}>
          <Container paddingX={{ xs: 2, sm: 4 }}>
            <Typography
              variant={'h4'}
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              Company terms
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: theme.palette.common.white,
              }}
            >
              Effective <strong>1 November, 2021</strong>
            </Typography>
          </Container>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            width={1}
            marginBottom={-1}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Container
          paddingTop={'0 !important'}
          paddingX={{ xs: 2, sm: 4 }}
          position={'relative'}
          top={0}
        >
          <Box
            component={Grid}
            container
            spacing={4}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid sx={{textAlign: "justify"}} item xs={12} md={9}>
              <Typography className="p1"><strong>Who we are and how to contact us</strong></Typography>
              <Typography className="p2">Talentvisa.uk is a site operated by Sterling Lawyers Ltd (&quot;We&quot;). We are registered in England and Wales under company number 09711065 and have our registered office at Fleet House, 8-12 New Bridge Street, London, EC4V 6AL.<span className="Apple-converted-space">&nbsp;</span></Typography>
              <Typography mt={1} className="p2">We are regulated by the Solicitors Regulation Authority.</Typography>
              <Typography mt={1} className="p1">To contact us, please email navdeep@sterlinglawyers.co.uk</Typography>
              <Typography mt={3} className="p1"><strong>By using our site you accept these terms</strong></Typography>
              <Typography className="p2">By using our site, you confirm that you accept these terms of use and that you agree to comply with them.</Typography>
              <Typography mt={1} className="p2">If you do not agree to these terms, you must not use our site.</Typography>
              <Typography mt={1} className="p1">We recommend that you print a copy of these terms for future reference.</Typography>
              <Typography mt={3} className="p1"><strong>There are other terms that may apply to you</strong></Typography>
              <Typography className="p2">These terms of use refer to the following additional terms, which also apply to your use of our site:</Typography>
              <ul className="ul1">
                <li><Typography>Our <a target="_blank" href="/privacy">Privacy Policy</a><span className="Apple-converted-space">&nbsp;&nbsp;</span>See further under <span className="s3">How we may use your personal information</span>.</Typography></li>
                <li><Typography>Our <a target="_blank" href="/cookies">Cookie Policy</a>, which sets out information about the cookies on our site</Typography></li>
              </ul>
              <Typography mt={3} className="p1"><strong>We may make changes to these terms</strong></Typography>
              <Typography className="p1">We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.<span className="Apple-converted-space">&nbsp;</span></Typography>
              <Typography mt={3} className="p1"><strong>We may make changes to our site</strong></Typography>
              <Typography className="p1">We may update and change our site from time to time to reflect changes to our products, our users&apos; needs and our business priorities. We will try to give you reasonable notice of any major changes.</Typography>
              <Typography mt={3} className="p1"><strong>We may suspend or withdraw our site</strong></Typography>
              <Typography  mt={1} className="p2">Our site is made available free of charge.</Typography>
              <Typography  mt={1}className="p2">We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</Typography>
              <Typography  mt={1}className="p2">You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</Typography>
              <Typography mt={3} className="p2"><strong>Disclaimer</strong></Typography>
              <Typography  mt={1}className="p2">The information displayed on Talentvisa.uk is intended to guide users through the various requirements associated with the UK&rsquo;s Global Talent Visa and preliminary assessment of users&rsquo; eligibility.<span className="Apple-converted-space">&nbsp;</span></Typography>
              <Typography  mt={1}className="p2">Please note that this is not a legal advice and Talentvisa.uk provides preliminary screening for users only, without considering their full details and documentation. If you wish to obtain a legal advice, may contact a <a target="_blank" href="https://www.gov.uk/find-an-immigration-adviser"><em>regulated immigration lawyer</em></a> to discuss your case.</Typography>
              <Typography  mt={1}className="p2">All information contained on this site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information contained on this site. In particular, we take no responsibility for the accuracy of information provided by the user in using this website.</Typography>
              <Typography  mt={1}className="p2">If you wish to complain regarding the service provided by Talentvisa.uk please contact us<span className="Apple-converted-space">&nbsp;&nbsp;</span>in the first instance and we will do our best to resolve the issue.</Typography>
              <Typography  mt={1}className="p1">Full details of our complaint handling process are available on request.</Typography>
              <Typography mt={3} className="p1"><strong>We may transfer this agreement to someone else</strong></Typography>
              <Typography   mt={1} className="p1">We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</Typography>
              <Typography mt={3}className="p1"><strong>You must keep your account details safe</strong></Typography>
              <Typography  mt={1}className="p2">If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</Typography>
              <Typography  mt={1}className="p2">We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</Typography>
              <Typography  mt={1}className="p1">If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us.</Typography>
              <Typography mt={3} className="p1"><strong>How you may use material on our site</strong></Typography>
              <Typography  mt={1}className="p2">We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</Typography>
              <Typography  mt={1}className="p2">You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</Typography>
              <Typography  mt={1}className="p2">You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</Typography>
              <Typography  mt={1}className="p2">Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged (except where the content is user-generated).</Typography>
              <Typography  mt={1}className="p2">You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</Typography>
              <Typography  mt={1}className="p1">If you print off, copy, download, share or repost any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</Typography>
              <Typography mt={3} className="p1"><strong>No text or data mining, or web scraping</strong></Typography>
              <Typography  mt={1} className="p2">You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):</Typography>
              <ul className="ul1">
                <li><Typography>Any &quot;robot&quot;, &quot;bot&quot;, &quot;spider&quot;, &quot;scraper&quot; or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.</Typography></li>
                <li><Typography>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</Typography></li>
              </ul>
              <Typography  mt={1}className="p2">The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive (<em>(EU) 2019/790</em>).</Typography>
              <Typography  mt={1}className="p1">This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.</Typography>
              <Typography mt={3} className="p1"><strong>Do not rely on information on this site</strong></Typography>
              <Typography  mt={1}className="p2">The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</Typography>
              <Typography  mt={1}className="p1">Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</Typography>
              <Typography mt={3} className="p1"><strong>We are not responsible for websites we link to</strong></Typography>
              <Typography  mt={1}className="p2">Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</Typography>
              <Typography  mt={1}className="p1">We have no control over the contents of those sites or resources.</Typography>
              <Typography mt={3} className="p1"><strong>Our responsibility for loss or damage suffered by you</strong></Typography>
              <Typography  mt={1}className="p2"><strong>Whether you are a consumer or a business user</strong>:</Typography>
              <ul className="ul1">
                <li><Typography>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</Typography></li>
              </ul>
              <Typography className="p2"><strong>If you are a business user</strong>:</Typography>
              <ul className="ul1">
                <li><Typography>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</Typography></li>
                <li><Typography>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</Typography></li>
                <li><Typography>use of, or inability to use, our site; or</Typography></li>
                <li><Typography>use of or reliance on any content displayed on our site.</Typography></li>
                <li><Typography>In particular, we will not be liable for:</Typography></li>
                <li><Typography>loss of profits, sales, business, or revenue;</Typography></li>
                <li><Typography>business interruption;</Typography></li>
                <li><Typography>loss of anticipated savings;</Typography></li>
                <li><Typography>loss of business opportunity, goodwill or reputation; or</Typography></li>
                <li><Typography>any indirect or consequential loss or damage.</Typography></li>
              </ul>
              <Typography className="p2"><strong>If you are a consumer user</strong>:</Typography>
              <ul className="ul1">
                <li><Typography>Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</Typography></li>
                <li><Typography>If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.</Typography></li>
              </ul>
              <Typography mt={3} className="p1"><strong>How we may use your personal information</strong></Typography>
              <Typography  mt={1}className="p1">We will only use your personal information as set out in our <a target="_blank" href="/privacy">Privacy Policy</a></Typography>
              <Typography mt={3} className="p1"><strong>We are not responsible for viruses and you must not introduce them</strong></Typography>
              <Typography  mt={1}className="p2">We do not guarantee that our site will be secure or free from bugs or viruses.</Typography>
              <Typography  mt={1}className="p2">You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</Typography>
              <Typography  mt={1}className="p1">You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</Typography>
              <Typography mt={3} className="p1"><strong>Rules about linking to our site</strong></Typography>
              <Typography mt={1} className="p2">You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</Typography>
              <Typography mt={1} className="p2">You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</Typography>
              <Typography mt={1} className="p2">You must not establish a link to our site in any website that is not owned by you.</Typography>
              <Typography mt={1} className="p2">Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</Typography>
              <Typography mt={1} className="p2">We reserve the right to withdraw linking permission without notice.</Typography>
              <Typography mt={1} className="p1">If you wish to link to or make any use of content on our site other than that set out above, please contact us.</Typography>
              <Typography mt={3} className="p1"><strong>Which country&apos;s laws apply to any disputes?</strong></Typography>
              <Typography  mt={1}className="p2">If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</Typography>
              <Typography  mt={1}className="p2">If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                position={'sticky'}
                top={theme.spacing(10)}
                className={'sticky'}
              >
                <ContactCard />
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default CompanyTerms;
