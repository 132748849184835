import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const dictionary = {
  home: {
    en: "Home",
    ru: "Главная"
  },
  gtv: {
    en: "Global Talent Visa",
    ru: "Виза Талантов"
  }
}

const Footer = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box sx={{ mt: 3, textAlign: { xs: "center", md: "right" } }}>
      <Grid sx={{ mb: 2 }} container spacing={2}>
        <Grid item md={5} xs={12}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box
              display={'flex'}
              component="a"
              href="/"
              title="Sterling Law"
              width={80}
            >
              <Box
                component={'img'}
                src={
                  mode === 'light'
                    ? 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
                    : 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
                }
                height={1}
                width={1}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={2} xs={12}>
          <Link underline="none" href="/">
            <Typography
              variant={'subtitle2'}
              color="text.secondary"
              gutterBottom
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
            >
              {dictionary.home[lang]}
            </Typography>
          </Link>
          <Link underline="none" href="/global-talent-visa">
            <Typography
              variant={'subtitle2'}
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
              color="text.secondary"
              gutterBottom
            >
             {dictionary.gtv[lang]}
            </Typography>
          </Link>
          <Link underline="none" href="/FAQ">
            <Typography
              variant={'subtitle2'}
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
              color="text.secondary"
              gutterBottom
            >
              FAQ
            </Typography>
          </Link>
        </Grid>
        <Grid item md={2} xs={12}>



          <Link target="_blank" underline="none" href="/terms">
            <Typography
              variant={'subtitle2'}
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
              color="text.secondary"
              gutterBottom
            >
              Terms & Conditions
            </Typography>
          </Link>
          <Link target="_blank" underline="none" href="/privacy">
            <Typography
              variant={'subtitle2'}
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
              color="text.secondary"
              gutterBottom
            >
              Privacy policy
            </Typography>
          </Link>
          <Link  target="_blank" underline="none" href="/cookies">
            <Typography
              variant={'subtitle2'}
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
              color="text.secondary"
              gutterBottom
            >
              Cookie Policy
            </Typography>
          </Link>


          <Typography
            variant={'subtitle2'}
            sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
            color="text.secondary"
            gutterBottom
          >
            <a className="cky-banner-element">Cookie settings</a>
          </Typography>

        </Grid>
        <Grid item md={3} xs={12}>
          <Link underline="none" href="tel:442078228535">
            <Typography
              variant={'subtitle2'}

              color="text.secondary"
              gutterBottom
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
            >
              +44 2078 228535
            </Typography>
          </Link>
          <Link underline="none" href="tel:440305966531">
            <Typography
              variant={'subtitle2'}
              color="text.secondary"

              gutterBottom
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
            >
              +44 7305 966531
            </Typography>
          </Link>
          <Link underline="none" href="mailto:contact@sterling-law.co.uk">
            <Typography
              variant={'subtitle2'}
              color="text.secondary"

              gutterBottom
              sx={{ ":hover": { cursor: "pointer", color: "grey", textDecoration: "underline" } }}
            >
              contact@sterling-law.co.uk
            </Typography>
          </Link>
          <Box sx={{ mt: 2 }}>
            <Link target="_blank" sx={{ m: 1 }} underline="none" href="https://www.linkedin.com/company/slallp">
              <LinkedInIcon />
            </Link>
            <Link target="_blank" sx={{ m: 1 }} underline="none" href="https://www.youtube.com/channel/UCP_368tcExrIIPr30MhEgUw">
              <YouTubeIcon />
            </Link>
            <Link target="_blank" sx={{ m: 1 }} underline="none" href="https://www.instagram.com/sterlinglawuk/">
              <InstagramIcon />
            </Link>
            <Link target="_blank" sx={{ m: 1 }} underline="none" href="https://www.facebook.com/sterlinglawuk/">
              <FacebookIcon />
            </Link>
            <Link target="_blank" sx={{ ml: 1 }} underline="none" href="https://twitter.com/sterlinglawuk">
              <TwitterIcon />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Box>

        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
          sx={{ mt: 2 }}
        >
          &copy; 2021 Sterling Law Ltd. All rights reserved.
        </Typography>

      </Box>
    </Box>
  );
};

export default Footer;
