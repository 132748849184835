import { Box, Typography } from "@mui/material";

interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}

const archClarifications: Questions = {
    1: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    Tech nation does not provide specific requirement
                    we interpret it to include total accumulated
                    time of employment and self-employment terms.
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description">
                Tech Nation (аккредитованная спонсорская организация) не предоставляет конкретных требований к опыту работы. Мы интерпретируем ответ на данный вопрос как общее накопленное время работы и самозанятости в сфере технологий. 
                </Typography>
            </Box>
        }
    },
    2: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Please note the following professions are <b>NOT eligible</b>:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <ol>
                        <li>Design work that is not in a Visual Arts context, i.e. not created for exhibition purposes, such as – graphic design, product design, industrial design, promotional work, advertising, commercial design, branding, visual identity, craft design, service design, typography.</li>
                        <li>Technical, scientific, medical, architectural or fashion illustration</li>
                        <li>Instrument makers</li>
                    </ol>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Обратите внимание, что нижеследующие профессии <b>не входят</b> в параметры данной категории:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <ol>
                        <li>Дизайнерская работа не входящая в сферу изобразительных искусств (т.е. созданная для коммерческих, а не выставочных целей – например, графический дизайн, проектирование изделий, промышленный дизайн, работа для рекламной деятельности, брендинг, создание визуальной идентичности бренда, ремесленный дизайн, дизайн услуг, типографика</li>
                        <li>Техническая, научная, медицинская, архитектурная или модная иллюстрация</li>
                        <li>Создание инструментов</li>
                    </ol>
                </Typography>
            </Box>
        }
    },
    3: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Such as detailed independent reviews, critiques, evaluations or profiles of your individual work, from credible critics in recognised newspapers or magazines, or on television, radio or websites.</p>
                <p>Promotional interviews, articles or features that solely talk about your career, or that are publicising upcoming events are not acceptable</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Признание/огласка может произойти следующим образом:</p>
                <p>Например, подробные независимые обзоры работ, критика, оценки или описание индивидуальной работы автора авторитетными критиками в известных газетах или журналах, на телевидении, радио или веб-сайтах.</p>
                <p>Недопустимо к зачислению в данную категорию следующее:</p>
                <p>Рекламные интервью, статьи, в которых рассказывается исключительно о карьере автора или которые рекламируют предстоящие события с участием автора.</p>
            </Typography>
        }
    },
    4: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Nationally refers to media outlets recognized in your country of residence (one country)</p>
                <p>Internationally refers to media outlets with international recognition and exposure</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Ответ «на национальном уровне» подразумевает огласку в средствах массовой информации в стране проживания (и только в этой стране)</p>
                <p>Ответ «на международном уровне» подразумевает огласку в средствах массовой информации имеющих международное признание и известность</p>
            </Typography>
        }
    },
    5: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">The award should provide significant recognition for the quality of your work</Typography>,
            ru: <Typography id="modal-modal-description">Для зачета в эту категорию, премия должна представлять собой значимое и уважаемое признание Вашей работы</Typography>
        }
    },
    6: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    7: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    8: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    9: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Evidence can include programmes, adverts, posters, or listings for events you took part in. It can also include screenshots of videos, or photographs of events you took part in. Or, if your work is not just performance based, your evidence can include distribution lists showing the number of countries your work has been sold in and the number of plays, sales or downloads.</p>
                <p>Also consider evidence of appearances in academic programmes of work, alongside evidence of professional appearances, such as an appearance in a graduate showcase. Your evidence can therefore include a mix of professional and academic work.</p>

            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Доказательства могут включать в себя:</p>
                <ul>
                    <li>программы</li>
                    <li>рекламные объявления</li>
                    <li>плакаты</li>
                    <li>списки мероприятий с участием автора</li>
                </ul>
                
                <p>Также возможно предоставить скриншоты видео или фотографии событий, в которых автор работ принимал участие.</p>
                <p>Если работа включает в себя не только результат, но и сам процесс (к примеру, выступление), то доказательства могут включать в себя список продаж и заказов, с указанием количества стран, в которых были проданы работы автора, а также количество их воспроизведений, загрузок, продаж.</p>
                <p>Также возможно рассмотреть следующее:</p>
                <ul>
                    <li>Свидетельства об участии в академических программах, наряду с доказательствами профессиональных выступлений (например, на дипломной презентации).</li>
                    <li>Таким образом, доказательства могут сочетать в себе примеры профессиональной и академической деятельности.</li>
                </ul>
            </Typography>
        }
    },
    10: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    }
}

console.log(archClarifications);

export default archClarifications;