import { Box, Typography } from "@mui/material";

interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}

const artClarifications: Questions = {
    1: {
        options: {
            '1': {
                ru: <Box><h3><b></b>Комбинированные искусства</h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для тех, кто сочетает в своей работе разные области искусств, в разных дисциплинах, признанных аккредитованной организацией Arts Council (танцы, литература, музыка, театральные и изобразительные искусства). В эту категорию могут войти:</p>

                        <ol>
                            <li>Междисциплинарная художественная/творческая практика</li>
                            <li>Многопрофильная художественная/творческая практика</li>
                            <li>Интерактивное иcкусство и искусство посвященное праздничным событиям (например, фестивали и карнавалы)</li>
                        </ol>

                        <p>Arts Council также рассматривает кандидатуры людей, исполняющих технические функции или функции поддержки. К примеру, они могут рассмотреть тех, кто занимается художественными заказами, составлением творческих программ, кураторством, режиссурой и руководством, touring networks и вовлечением общественности.</p>

                    </Typography></Box>,
                en: <Box><h3><b>Combined arts</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>People who work across different areas of arts practice,
                            in one or more of Art Council supported disciplines
                            (dance, literature, music, theatre and visual arts), such as:</p>

                        <ol>
                            <li>interdisciplinary arts practise</li>
                            <li>multi-disciplinary arts practise</li>
                            <li>participatory and celebratory work</li>
                            <li>festivals and carnival arts </li>
                        </ol>

                        <p>Art council also considers applications from people working
                            in technical or non-creative roles, such as commissioning,
                            programming or curation, production, directing or developing work,
                            touring networks and public engagement.</p>

                    </Typography></Box>

            },
            '2': {
                ru: <Box><h3><b>Искусство танца</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для профессиональных танцоров, исполнителей и специалистов, занимающихся:</p>

                        <ol>
                            <li>балетом</li>
                            <li>современными танцами</li>
                            <li>воздушными танцами</li>
                            <li>уличными танцами</li>
                            <li>народными танцами</li>
                            <li>джазовыми танцами и чечёткой</li>
                            <li>развлекательными танцевальными программами (имеются ввиду танцевальные направления связанные с основной индустрией развлечений, к примеру, с мюзиклами, джазом, четкой и такими танцевальными мероприятиями как «Танцы на Льду» или «Повелитель Танца»)</li>
                        </ol>

                        <p>Arts Council также рассматривает кандидатуры людей, исполняющих технические функции или функции поддержки. К примеру, они могут рассмотреть тех, кто занимается хореографией, преподаванием, режиссурой и руководством или кураторством.</p>

                    </Typography></Box>,
                en: <Box><h3><b>Dance</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Dancers, performers, and practitioners working in areas such as:</p>

                        <ol>
                            <li>ballet</li>
                            <li>contemporary dance</li>
                            <li>aerial dance</li>
                            <li>street dance</li>
                            <li>folk, traditional and culturally specific dance</li>
                            <li>social dance (Ceilidhs, barn dance, tea dance, line dancing, salsa, jive, Lindy Hop, ballroom)</li>
                            <li>jazz and tap</li>
                            <li>entertainment dance (dance styles associated with the mainstream entertainment industry such as musicals, jazz and tap dancing and dance spectaculars such as ‘Dancing on Ice’ or ‘Lord of the Dance’)</li>

                        </ol>

                        <p>Art council also consider applications from people working in technical or non-creative roles such as choreography or teaching, production, direction, and curation.</p>

                    </Typography></Box>,
            },
            '3': {
                ru: <Box> <h3><b>Литература</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для писателей или литературных специалистов, работающих в следующих областях:</p>
                        <ol>
                            <li>Печатная, мультимедийная или устная поэзия</li>
                            <li>Печатная, мультимедийная или устная проза (включая романы, новеллы, короткие рассказы и комиксы)</li>
                            <li>Рассказы и иллюстрации для детей и подростков</li>
                            <li>Графические романы и комиксы</li>
                            <li>Независимое издательство (любого из вышеприведенных примеров)</li>
                            <li>Устная литература и повествование</li>
                            <li>Перевод (поэзии или прозы)</li>
                            <li>Литературные программы и фестивали</li>
                            <li>Писательское развитие</li>
                        </ol>
                        <p>Те, кто использует инновационный и творческий поход к документальной/научно-популярной литературе, которая представляет собой литературную ценность, могут быть рассмотрены в качестве кандидатов. Примеры такой работы могу включать в себя нестандартный подход к форме повествования или жанру, такому как биография, мемуары или книги о путешествиях. Обращаем внимание, что некоторые произведения не будут рассматриваться в этой категории, если они входят в области журналистики, журналистских исследований, общая литература об искусстве, искусствоведческие произведения, спортивная и историческая литература или же статьи для журналов о стиле жизни.</p>
                    </Typography></Box>,
                en: <Box> <h3><b>Literature</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Writers and literature practitioners working in areas such as:</p>

                        <ol>
                            <li>print, digital or live poetry</li>
                            <li>print, digital or live prose fiction (including novels, novellas and short fiction, graphic novels)</li>
                            <li>writing and illustration for children and young people</li>
                            <li>graphic novels and comics </li>
                            <li>independent publishing (of any of the above)</li>
                            <li>live literature and storytelling</li>
                            <li>translation (of poetry and prose fiction)</li>
                            <li>literature festivals and programming</li>
                            <li>writer development </li>
                        </ol>

                        <p>Those who use an innovative and creative approach to non-fiction work, that demonstrates strong literary merit (for example, where the work experiments creatively with form or genre such as biography, memoir or travel writing) can be considered. Areas such as journalism, investigative journalism, general arts writing, academic writing about the arts, sports writing, historical writing or writing for lifestyle magazines are not supported.</p>
                    </Typography></Box>,
            },
            '4': {
                en: <Box><h3><b>Music</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Musicians, performers, and practitioners working in areas such as:</p>
                        <li>classical and orchestral music</li>
                        <li>baroque, chamber, and early music</li>
                        <li>brass or silver bands</li>
                        <li>marching or concert bands</li>
                        <li>opera</li>
                        <li>contemporary music (such as pop, rock, indie and alternative, metal and punk, soul, country, funk, hip-hop, R&B, grime, urban, blues, electronic, experimental, DJ’s and producers that create original music or remixes/edits, turntablists and singer-songwriters) </li>
                        <li>folk, traditional and culturally specific music</li>
                        <li>jazz</li>
                        <li>choral or gospel</li>
                        <li>youth music</li>
                        <li>grassroots music</li>
                        <li>independent record labels</li>

                        <p>Art council also consider applications from people working in technical or non-creative roles such as production, recording and engineering, and promoters/curators of music events. Song writers, stage and concert composers, conductors and musical directors can also be considered.</p>

                    </Typography></Box>,
                ru: <Box><h3><b>Музыка</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для музыкантов, музыкальных исполнителей и специалистов в нижеперечисленных областях и жанрах:</p>
                        <li>Классическая музыка и музыкальный оркестр</li>
                        <li>Камерная и ранняя музыка, а также музыка барокко</li>
                        <li>Духовой оркестр</li>
                        <li>Марширующие и концертные оркестры</li>
                        <li>Оперная музыка</li>
                        <li>Современная музыка (к примеру поп, рок, инди и альтернативная музыка, метал, панк, соул, кантри, фанк, хип-хоп, R&B, грайм, урбанистическая музыка, блюз, электронная и экспериментальная музыка, ремиксы и эдиты, тернтейблисты и писатели-исполнители)</li>
                        <li>Народная музыка</li>
                        <li>Джаз</li>
                        <li>Хоровая музыка и госпелы</li>
                        <li>Молодежная музыка</li>
                        <li>Музыка grassroots</li>
                        <li>Музыка независимых звукозаписывающих компаний</li>
                        <p>Arts Council так же рассматривает кандидатуры людей, исполняющих технические функции или функции поддержки. К примеру, они могут рассмотреть тех, кто занимается постановкой, записью или инженерией, а так же рекламой и кураторством музыкальных мероприятий. Также могут быть рассмотрены кандидатуры авторов песен, сценических и концертных композиторов, а также дирижеров и музыкальных руководителей.</p>
                    </Typography></Box>
            },
            '5': {
                en: <Box><h3><b>Theatre</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Actors and theatre practitioners working in areas such as:</p>
                        <ol>
                            <li>musical theatre</li>
                            <li>physical theatre and mime</li>
                            <li>plays or narrative drama (excluding for film and television)</li>
                            <li>experimental theatre</li>
                            <li>comedy (such as comedy plays, radio comedy or touring stand-ups but excluding work created for screen, film and television, which would come under PACT criteria)</li>
                            <li>variety and cabaret</li>
                            <li>pantomime</li>
                            <li>circus</li>
                            <li>puppetry and visual theatre</li>
                            <li>audience development</li>
                            <li>youth theatre and Theatre in Education (excluding Drama in Education)</li>
                            <li>new writing</li>
                        </ol>
                        <p>Art Council also consider applications from people working in technical or non-creative roles such as production, directing, writing, dramaturgy, design (e.g. lighting, set or costume design) and stage management.</p>
                    </Typography></Box>,
                ru: <Box><h3><b>Театральное искусство</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для актеров и специалистов в области театральных искусств, таких как:</p>
                        <ol>
                            <li>музыкальный театр</li>
                            <li>физический театр и пантомима</li>
                            <li>пьесы и повествовательное выступление (за исключением игры в фильмах или на телевидении)</li>
                            <li>экспериментальный театр</li>
                            <li>комедия (например, комедийная пьеса, комедийные радио-выступления или гастролирующий стенд-ап, за исключением работы на экране, в фильмах или на телевидении, которые подпадают под критерии PACT)</li>
                            <li>эстрадные выступления и кабаре</li>
                            <li>пантомима</li>
                            <li>цирковое искусство</li>
                            <li>выступления с марионетками и театр с визуальными эффектами</li>
                            <li>расширение аудитории</li>
                            <li>молодежный и образовательный театр (за исключением драматургии)</li>
                            <li>New Writing</li>
                        </ol>
                        <p>Arts Council так же рассматривает кандидатуры людей, исполняющих технические функции или функции поддержки. К примеру, они могут рассмотреть тех, кто занимается театральной постановкой, режиссурой, написанием сценариев, драматургией, дизайном (например освещением, сценическим дизайном или подборкой костюмов) и постановочным делом.</p>
                    </Typography></Box>
            },
            '6': {
                en: <Box><h3><b>Visual Arts </b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <p>Artists, arts practitioners, or designer/makers working in areas such as:</p>
                        <ol>
                            <li>drawing, painting or printmaking</li>
                            <li>sculpture and installations</li>
                            <li>design (such as research and development, design and production of prototypes where the work is shared publicly in exhibitions but excluding design or production of functional items intended for mass production. See Ineligible disciplines guidance for details of areas that are not supported) </li>
                            <li>graphic design (when it is created specifically for exhibition, therefore excluding promotional or commercial design. See Ineligible disciplines guidance for details of areas that are not supported) </li>
                            <li>artist-led animation (excluding work primarily intended for film or television, commercial games or mass-market media distribution) </li>
                            <li>illustration (such as concept art, comic art, picture books, zines and other narrative or sequential illustration)</li>
                            <li>live and performance art</li>
                            <li>mixed media art, digital or computer-generated art </li>
                            <li>ceramics, pottery or other handmade crafts (such as woodworking or turning, silversmithing or metal work, decorative jewellery, glass work, textiles or traditional heritage crafts)</li>
                            <li>artistic photography (excluding areas such as editorial, fashion or journalism, or work created for marketing campaigns, advertising or branding)</li>
                            <li>artists film and moving image (excluding narrative feature, short film, documentary, or animation work that falls under film and television criteria) </li>
                            <li>sound art (such as site-specific installations or sound installations, ‘audioscapes’, sound walks and sound mapping and performance art, or noise or sonic art)</li>
                            <li>Virtual Reality or Augmented Reality (when used for exhibitions and installations in galleries or museums, but excluding when created for gaming, branding, promotion, marketing or advertising, architecture, engineering, retail, healthcare, events and conferences, tourism or sports) </li>
                        </ol>
                        <p>Art Council also consider applications from people working in technical or non-creative areas such as curating, production or directing.</p>
                    </Typography></Box>,
                ru: <Box><h3><b>Изобразительные искусства</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <p>категория для художников, специалистов по искусству и дизайнеров, работающих в нижеперечисленных областях:</p>
                        <ol>
                            <li>рисование, живопись или гравюра</li>
                            <li>скульптура и инсталляция</li>
                            <li>дизайн ( исследования и разработки, дизайн и изготовление прототипов, которые могут выставлены на публичных выставках. Эта категория не включает в себя дизайн или продукцию для массового изготовления. Пожалуйста обратитесь к руководству о дисциплинах не подходящих под критерии данной категории для более подробной информации)</li>
                            <li>графический дизайн (когда таковой создан специально для выставки, соответственно исключая из данной категории дизайн созданный для рекламных или коммерческих целей. Пожалуйста обратитесь к руководству о дисциплинах не подходящих под критерии данной категории для более подробной информации)</li>
                            <li>художественная анимация (за исключением работы созданной главным образом для использования в фильмах и на телевидении, в коммерческих играх или же распространения в средствах массовой информации</li>
                            <li>иллюстрация (например, концепт-арт, комиксы, книги с картинками, журналы и другая повествовательная или последовательная иллюстрация)</li>
                            <li>живое и исполнительное искусство</li>
                            <li>искусство с применением различных техник, цифровое искусство, а также искусство созданное посредством использования компьютерных технологий</li>
                            <li>керамика, работа с глиной и другие ручные искусства (например, работа по дереву, изготовление изделий из серебра и других металлов, ювелирное искусство, работа по стеклу, текстилю и традиционные народные искусства)</li>
                            <li>искусство фотографии (исключая редакцию/издательство, модную фотографию, фотожурналистику, или фотографии сделанные для маркетинговых кампаний, рекламы или поддержки определенного бренда)</li>
                            <li>кинематография (исключая повествовательные репортажи, короткометражные фильмы, документальные фильмы или анимацию, которая подходит под критерии категории «фильм и телевидение»)</li>
                            <li>звуковое искусство (например, звуковые инсталляции созданные для конкретных участков, «аудиокадры», звуковые прогулки, звуковое отображение, исполнение, шум или акустическое искусство)</li>
                            <li>виртуальная реальность или же дополненная реальность (при условии использования этих техник для выставок и инсталляций в галереях или музеях, но за исключением случаев такого использования в играх, целях брендинга, рекламы или маркетинга, в архитектурных проектах, инженерии, для розничной торговли, здравоохранения, на мероприятиях и конференциях, в целях развития туризма или спорта)</li>
                        </ol>
                        <p>Arts Council так же рассматривает кандидатуры людей, исполняющих технические функции или функции поддержки. К примеру, они могут рассмотреть тех, кто занимается кураторством, производством проектов или режиссурой.</p>
                    </Typography></Box>
            },
            '7': {
                en: <Box><h3><b>Музеи и Галереи</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Artists, arts practitioners, and curators who deliver arts focused activity in museum or gallery settings.</p>
                    </Typography></Box>,
                ru: <Box><h3><b>Museums and Galleries</b></h3>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Категория для художников, специалистов по искусству или кураторов, которые занимаются творческими проектами специально для их выставки в музеях и галереях.</p>
                    </Typography></Box>
            }
        },
        clarification: {
            ru: null,
            en: null
        }
    },
    2: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Please note the following professions are <b>NOT eligible</b>:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <ol>
                        <li>Hair styling</li>
                        <li>Make up for fashion</li>
                        <li>Fashion stylists</li>
                        <li>Editorial and fashion photography</li>
                        <li>Commercial photography</li>
                        <li>Photography for marketing campaigns, advertising or branding</li>
                        <li>Modelling</li>
                        <li>Journalism - written and photographic</li>
                        <li>Investigative journalism</li>
                        <li>Historians</li>
                        <li>Manufacturing of functional items such as textiles or furniture</li>
                        <li>Working in museums and galleries in non-creative roles such as front of house or administration</li>
                        <li>Design work that is not in a Visual Arts context, i.e. not created for exhibition purposes, such as – graphic design, product design, industrial design, promotional work, advertising, commercial design, branding, visual identity, craft design, service design, typography</li>
                        <li>Technical, scientific, medical, architectural or fashion illustration</li>
                        <li>Factual podcasts</li>
                        <li>Digital content creators such as YouTube channels and Instagram influencers/ models</li>
                        <li>Art dealers/collectors</li>
                        <li>Art critics</li>
                        <li>Instrument makers</li>
                        <li>Tattoo artists</li>
                        <li>Martial arts</li>
                        <li>Professional wrestling</li>
                    </ol>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Обратите внимание, что нижеследующие профессии <b>не входят</b> в параметры данной категории:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <ol>
                        <li>Укладка волос</li>
                        <li>Искусство визажа</li>
                        <li>Работа модных стилистов </li>
                        <li>Редакционная и модная фотография</li>
                        <li>Коммерческая фотография</li>
                        <li>Фотография для маркетинговых кампаний, рекламы и брендинга</li>
                        <li>Моделирование</li>
                        <li>Журнализм – в письменном, а так же фотографическом формате</li>
                        <li>Журналистские расследования </li>
                        <li>Исторические работы</li>
                        <li>Изготовление функциональных предметов обихода, например, текстиля или мебели</li>
                        <li>Работа в музеях и галереях в технических или поддерживающих ролях, например, работа на ресепшене или в администрации</li>
                        <li>Дизайнерская работа, не относящаяся к сфере визуального искусства (то есть, работы, созданные не для выставочных целей, к примеру работы графического дизайна, проектирование изделий, промышленный дизайн, работа для рекламной деятельности, брендинг, создание визуальной идентичности бренда, ремесленный дизайн, дизайн услуг, типографика</li>
                        <li>Техническая, научная, медицинская, архитектурная или модная иллюстрация</li>
                        <li>Документальные подкасты</li>
                        <li>Создание контента в цифровом формате, например для Youtube каналов или других социальных сетей (к примеру, моделирование для Instagram)</li>
                        <li>Торговля/коллекционирование произведений искусства</li>
                        <li>Искусствоведение/художественная критика</li>
                        <li>Произведение музыкальных инструментов</li>
                        <li>Татуаж</li>
                        <li>Боевые искусства</li>
                        <li>Профессиональный рестлинг</li>
                    </ol>
                </Typography>
            </Box>
        }
    },
    3: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p>Such as detailed independent reviews, critiques, evaluations or profiles of your individual work, from credible critics in recognised newspapers or magazines, or on television, radio or websites.</p>
                <p>Promotional interviews, articles or features that solely talk about your career, or that are publicising upcoming events are not acceptable</p>
            </Typography>,
            ru: <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p>Признание/огласка может произойти следующим образом:

Например, подробные независимые обзоры работ, критика, оценки или описание индивидуальной работы автора авторитетными критиками в известных газетах или журналах, на телевидении, радио или веб-сайтах</p>
                <p>Недопустимо к зачислению в данную категорию следующее:

Рекламные интервью, статьи, в которых рассказывается исключительно о карьере автора или которые рекламируют предстоящие события с участием автора </p>
            </Typography>
        }


    },
    4: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Nationally refers to media outlets recognized in your country of residence (one country)</p>
                <p>Internationally refers to media outlets with international recognition and exposure</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Ответ «на национальном уровне» подразумевает огласку в средствах массовой информации в стране проживания (и только в этой стране)</p>
                <p>Ответ «на международном уровне» подразумевает огласку в средствах массовой информации имеющих международное признание и известность</p>
            </Typography>
        }
    },
    5: {
        options: {},
        clarification: {
            ru: <Typography id="modal-modal-description">
                Для зачета в эту категорию, премия должна представлять собой значимое и уважаемое признание Вашей работы
            </Typography>,
            en: <Typography id="modal-modal-description">The award should provide significant recognition for the quality of your work</Typography>
        }

    },
    6: {
        options: {},
        clarification: { en: null, ru: null }
    },
    7: {
        options: {},
        clarification: { en: null, ru: null }
    },
    8: {
        options: {},
        clarification: { en: null, ru: null }
    },
    9: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Evidence can include programmes, adverts, posters, or listings for events you took part in. It can also include screenshots of videos, or photographs of events you took part in. Or, if your work is not just performance based, your evidence can include distribution lists showing the number of countries your work has been sold in and the number of plays, sales or downloads.</p>
                <p>Also consider evidence of appearances in academic programmes of work, alongside evidence of professional appearances, such as an appearance in a graduate showcase. Your evidence can therefore include a mix of professional and academic work.</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Доказательства могут включать в себя:</p>
                   <ul>
                        <li>программы</li>   
                        <li>рекламные объявления</li>   
                        <li>плакаты</li>   
                        <li>списки мероприятий с участием автора</li>   
                    </ul> 
                    <p>Также возможно предоставить в Arts Council скриншоты видео или фотографии событий, в которых автор работ принимал участие 
Если работа включает в себя не только результат, но и сам процесс (к примеру, выступление), то доказательства могут включать в себя список продаж и заказов, с указанием количества стран, в которых были проданы работы автора, а также количество их воспроизведений, загрузок, продаж. 
</p>
<p>Также возможно рассмотреть следующее:</p>
                   <ul>
                        <li>Свидетельства об участии в академических программах, наряду с доказательствами профессиональных выступлений (например, на дипломной презентации)</li>   
                        <li>Таким образом, доказательства могут сочетать в себе примеры профессиональной и академической деятельности</li>   
                    </ul> 
            </Typography>
        }
    },
    10: {
        options: {},
        clarification: { en: null, ru: null }
    }
}

export default artClarifications;
