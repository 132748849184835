import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Who we are and how to contact us',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>Talentvisa.uk is a site operated by Sterling Lawyers Ltd ("We"). We are registered in England and Wales under company number 09711065 and have our registered office at Fleet House, 8-12 New Bridge Street, London, EC4V 6AL.</p>
      <p>We are regulated by the Solicitors Regulation Authority.</p>
      <p>To contact us, please email navdeep@sterlinglawyers.co.uk</p>
      </Box>,
  },
  {
    title: 'By using our site you accept these terms',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>By using our site, you confirm that you accept these terms of use and that you agree to comply with them.</p>
      <p>If you do not agree to these terms, you must not use our site.</p>
      <p>We recommend that you print a copy of these terms for future reference.</p>
      </Box>,
  },
  {
    title: 'There are other terms that may apply to you',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
      <ul>
        <li>Our Privacy Policy LINK.  See further under How we may use your personal information.</li>
        <li>Our Cookie Policy LINK, which sets out information about the cookies on our site.</li>
      </ul>
      <p></p>
      </Box>,
  },
  {
    title: 'We may make changes to these terms',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
      </Box>,
  },
  {
    title: 'We may make changes to our site',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We may update and change our site from time to time to reflect changes to our products, our users' needs and our business priorities. We will try to give you reasonable notice of any major changes.</p>
      </Box>,
  },
  {
    title: 'We may suspend or withdraw our site',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>Our site is made available free of charge.</p>
      <p>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
      <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
      </Box>,
  },
  {
    title: 'Disclaimer',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>The information displayed on Talentvisa.uk is intended to guide users through the various requirements associated with the UK’s Global Talent Visa and preliminary assessment of users’ eligibility. </p>
      <p>Please note that this is not a legal advice and Talentvisa.uk provides preliminary screening for users only, without considering their full details and documentation. If you wish to obtain a legal advice, may contact a regulated immigration lawyer to discuss your case.</p>
      <p>All information contained on this site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information contained on this site. In particular, we take no responsibility for the accuracy of information provided by the user in using this website.</p>
      <p>If you wish to complain regarding the service provided by Talentvisa.uk please contact us  in the first instance and we will do our best to resolve the issue.</p>
      <p>Full details of our complaint handling process are available on request.</p>
      </Box>,
  },
  {
    title: 'We may transfer this agreement to someone else',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract</p>
      </Box>,
  },
  {
    title: 'You must keep your account details safe',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
      <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
      <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us.</p>
      </Box>,
  },
  {
    title: 'How you may use material on our site',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved</p>
      <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
      <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged (except where the content is user-generated).</p>
      <p>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
      <p>If you print off, copy, download, share or repost any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
      </Box>,
  },
  {
    title: 'No text or data mining, or web scraping',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):</p>
      <ul>
        <li>Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.</li>
        <li>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</li>
      </ul>
      <p>The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790).</p>
      <p>This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.</p>
      </Box>,
  },
  {
    title: 'Do not rely on information on this site',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>
      <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>
      </Box>,
  },
  {
    title: 'We are not responsible for websites we link to',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
      <p>We have no control over the contents of those sites or resources.</p>
      </Box>,
  },
  {
    title: 'Our responsibility for loss or damage suffered by you',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>Whether you are a consumer or a business user:</p>
      <ul>
        <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</li>
      </ul>

      <p>If you are a business user:</p>
      <ul>
        <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</li>
        <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
          <ul>
            <li>use of, or inability to use, our site; or</li>
            <li>use of or reliance on any content displayed on our site.</li>
          </ul>
        </li>
        <li>In particular, we will not be liable for:
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </li>
      </ul>
      <p>If you are a consumer user:</p>
      <ul>
        <li>Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
        <li>If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.</li>
      </ul>
      </Box>,
  },
  {
    title: 'How we may use your personal information',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We will only use your personal information as set out in our Privacy Policy LINK.</p>
      </Box>,
  },
  {
    title: 'We are not responsible for viruses and you must not introduce them',
    description:
      <Box sx={{textAlign: "justify"}}>
      <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
      <p>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>
      <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
      </Box>,
  },
  {
    title: 'Rules about linking to our site',
    description:
      <Box sx={{textAlign: "justify"}}>
        <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
        <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
        <p>You must not establish a link to our site in any website that is not owned by you.</p>
        <p>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
        <p>We reserve the right to withdraw linking permission without notice.</p>
        <p>If you wish to link to or make any use of content on our site other than that set out above, please contact us.</p>
      </Box>,
  },
  {
    title: "Which country's laws apply to any disputes?",
    description:
      <Box sx={{textAlign: "justify"}}>
        <p>If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
        <p>If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</p>
      </Box>,
  },
];

const PrivacySection = ({
  title,
  description,
}: {
  title: string;
  description: JSX.Element;
}) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

const Content = (): JSX.Element => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
