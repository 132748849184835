import { useContext, createContext, useState } from 'react';
import {
    Route,
    useHistory,
    Redirect,
    useLocation
  } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import jwt from 'jwt-decode'
import {
    Box,
    TextField,
    Button,
    Card
  } from '@mui/material';


export const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
        fakeAuth.isAuthenticated = true;
        cb() // fake async
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        cb()
    }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
export const authContext = createContext(undefined); //@ts-ignore

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}



export function useProvideAuth() {

    let token = localStorage.getItem('jwt');
    const [user, setUser] = useState(token);

    const signin = (cb) => {
        return fakeAuth.signin(() => {
            setUser("user");
            cb();
        });
    };

    const signout = cb => {
        localStorage.removeItem('jwt');
        setUser(null);
        return fakeAuth.signout(() => {
            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}

export function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export function useAuth() {
    return useContext(authContext);
}

export function LoginForm() {
    let history = useHistory();
    let location = useLocation<Location>();
    let auth = useAuth();
    let api = "http://sterlinglaw.dev.box:4000/";
    let path = "user/login";

    // @ts-ignore
    let { from } = location.state || { from: { pathname: "/" } };
    let login = (data: any) => {
        axios.post(`${api}${path}`,
            {
                "username": data.email,
                "password": data.password
            }
        ).then((response) => {
            localStorage.setItem('jwt', response.data.access_token);
            auth.signin(() => {
                history.replace(from);
            });
        });


    };

    let validationRawSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationRawSchema)
    });

    return (
        <Box sx={{ width: "25%", m: 'auto', mt: 5, position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Card variant="outlined" sx={{ py: 2 }}>
                <h1>Log in</h1>
                <p>You must log in to view your Account</p>
                <Box>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        sx={{ width: "90%" }}
                        margin="dense"
                        { //@ts-ignore
                        ...register('email')}
                        error={errors.email ? true : false}
                    />
                </Box>
                <Box>
                    <TextField
                        required
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        type="password"
                        label="Password"
                        sx={{ width: "90%" }}
                        margin="dense"
                        { //@ts-ignore
                        ...register('password')}
                        error={errors.password ? true : false}
                    />
                </Box>
                <Box><Button sx={{ width: "90%", my: 2, height: 50 }} variant="contained" onClick={handleSubmit(login)}>Log in</Button></Box>
            </Card>
        </Box>
    );
}