import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import './App.css';

import Account from './Account/Account';
import GlobalTalentVisa from './GlobalTalentVisa/GlobalTalentVisa';
import { ProvideAuth, LoginForm, PrivateRoute } from './LoginForm';
import { Main } from 'layouts';
import CompanyTerms from './CompanyTerms';
import PrivacyPolicy from './PrivacyPolicy';
import CookiesPolicy from './CookiesPolicy';
import Faq from './Faq';
import Page from 'components/Page';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import Logistics from './Logistics';
import LanguageProvider from './LanguageProvider';

const App = (): JSX.Element => {
  const lang = navigator.language === "ru-RU" ? "ru" : "en";
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", lang);
  }

  return (
    <LanguageProvider>
      <Page>
        <Main>
          <ProvideAuth>
            <Router>
              <Switch>
                <Route path="/global-talent-visa">
                  <GlobalTalentVisa />
                </Route>
                <Route path="/login">
                  <LoginForm />
                </Route>
                <Route path="/terms">
                  <CompanyTerms />
                </Route>
                <Route path="/privacy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/cookies">
                  <CookiesPolicy />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/">
                  <Logistics />
                </Route>
              </Switch>
            </Router>
          </ProvideAuth>
        </Main>
      </Page>
    </LanguageProvider>
  );
};

export default App;