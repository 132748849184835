import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from 'components/Container';
import { useState, useEffect, useContext } from 'react';
import LangugaeContext from 'components/LanguageContewxt';

const dictionary = {
  title: {
    en: "Watch the video",
    ru: "Посмотрите видео"
  },
  subtitle: {
    en: "",
    ru: ""
  },
}

const VideoB = (): JSX.Element => {
    //@ts-ignore
    const {lang, setLang}= useContext(LangugaeContext);
  return (
    <Box

      sx={{
        position: 'relative',
        backgroundImage:
          'url(https://images.unsplash.com/photo-1490642914619-7955a3fd483c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2093&q=80)',
        backgroundPosition: 'center center',
        backgroundSize: "cover",
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: '#11092d',
          opacity: 0.7,
          zIndex: 1,
        },
      }}
    >
      <Container position={'relative'} zIndex={3}>
        <Box data-aos="fade-up">
          <Box marginBottom={4}>
            <Typography
              variant={'h4'}
              gutterBottom
              align={'center'}
              sx={{ fontWeight: 700, color: 'common.white' }}
            >
              {dictionary.title[lang]}
            </Typography>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
              sx={{ color: 'common.white' }}
            >
              {dictionary.subtitle[lang]}
            </Typography>
          </Box>
          
          <Box
            sx={{
              height: 324,
              maxWidth: 600,
              margin: '0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              display: lang === "en" ? "flex" : "none"
            }}
          >

            <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/Ge_Er3BVS9k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Box>
          <Grid
            container
            spacing={{md: 4, xs: 0}}
            sx={{
              margin: '0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              display: lang !== "en" ? "flex" : "none"
            }}
          >
            <Grid item md={6} xs={12} sx={{
              height: 324,
             
            }}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5cd9wRugHyI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Grid>
            <Grid item md={6} xs={12} sx={{
              height: 324,
             
            }}>
            <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/dWmpc9bXRe8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default VideoB;
