import { Box, Typography } from "@mui/material";

interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}

const scienceClarifications: Questions = {
    1: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-title">
                Or equivalent research experience including industrial or clinical research.
            </Typography>,
            ru: <Typography id="modal-modal-title">
                Или эквивалентный исследовательский опыт (к примеру, промышленных или клинических исследований).
            </Typography>
        }
    },
    2: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-title">
                    Typically involved in research in a university, research institute or in industry;
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-title">
                Обычно активная научная деятельность подразумевает провождение исследований в университете, исследовательском институте или в индустрии
                </Typography>
            </Box>
        }
    },
    3: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-title">
                Equivalent to United Kingdom Academy of Medical Sciences, British Academy, the Royal Academy of Engineering or the Royal Society.
            </Typography>,
            ru: <Typography id="modal-modal-title">
                Имеется в виду организация эквивалентная Академии Медицинских Наук Великобритании, Британской Академии, Королевской Академии Инженеров или Королевскому Сообществу.
            </Typography>
        }
    },
    4: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    5: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Funding such as grants, fellowships, scholarship, private funding, etc.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Финансирование включает в себя гранты, стипендии, частное финансирование и т.д.
            </Typography>
        }
    },
    6: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Such as detailed independent reviews, recognition, critiques, evaluations or profiles of your individual work, from credible critics in recognised newspapers or magazines, or on television, radio or websites.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Например, подробные независимые обзоры работ, критика, оценки или описание индивидуальной работы автора авторитетными критиками в известных газетах или журналах, на телевидении, радио или веб-сайтах.
            </Typography>
        }
    },
    7: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Such as detailed independent reviews, recognition, critiques, evaluations or profiles of your work
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Например, подробные независимые обзоры работ, критика, оценки или описание индивидуальной работы автора.
            </Typography>
        }
    },
    8: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    9: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Kindly click on the <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-1-appointments/">link</a> for full list of recognized UK higher education institution (HEI) or research institute
                
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Вы можете перейти по <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-1-appointments/">ссылке</a>, чтобы увидеть полный список утвержденных британских высших учебных заведений (ВУЗов) и исследовательский институтов.
            </Typography>
        }
    },
    10: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Kindly click on the <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-1-appointments/">link</a> for full list of recognized UK higher education institution (HEI) or research institute
                
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Вы можете перейти по <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-1-appointments/">ссылке</a>, чтобы увидеть полный список утвержденных британских высших учебных заведений (ВУЗов) и исследовательский институтов.
            </Typography>
        }
    },
    11: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Kindly click on the <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-2-individual-fellowships/">link</a> for full list of recognized fellowships approved by the British Academy, Royal Academy of Engineering and the Royal Society
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Вы можете перейти по <a href="https://royalsociety.org/grants-schemes-awards/global-talent-visa/route-2-individual-fellowships/">ссылке</a> , чтобы увидеть полный список стипендий утвержденных Британской Академией, Королевской Академией Инженеров и Королевским Сообществом
            </Typography>
        }
    },
    12: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    The grant or award is worth a minimum of £30,000 and the grant or award covers a minimum period of 2 years.
                </Typography>
                <Typography id="modal-modal-description">
                Kindly click on the <a href="https://www.gov.uk/government/publications/ukri-endorsement-endorsed-funders-global-talent-visa/uk-research-and-innovation-endorsed-funders">link</a> for full list of endorsed funder approved by UKRI.
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description">
                Грант или премия минимум 30,000 фунтов стерлингов на минимальный период от двух лет.
                </Typography>
                <Typography id="modal-modal-description">
                Вы можете перейти по <a href="https://www.gov.uk/government/publications/ukri-endorsement-endorsed-funders-global-talent-visa/uk-research-and-innovation-endorsed-funders">ссылка</a>, чтобы увидеть полный список спонсоров, получивших одобрение UKRI.
                </Typography>
            </Box>
        }
    },
    13: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    Kindly click on the following <a href="https://www.gov.uk/government/publications/ukri-endorsement-employing-or-hosting-institutions-global-talent-visa/ukri-list-of-approved-research-organisations">link</a> for full list of eligible institutions approved by UKRI
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description">
                    Вы можете перейти по <a href="https://www.gov.uk/government/publications/ukri-endorsement-employing-or-hosting-institutions-global-talent-visa/ukri-list-of-approved-research-organisations">ссылке</a>, чтобы увидеть полный список организаций, получивших одобрение UKRI.
                </Typography>
            </Box>
        }
    },
    14: {
        options: {},
        clarification: {
            en: <Box>
                <Typography id="modal-modal-description">
                    Until 31 December 2021, academics and researchers wishing to work on COVID-19 related grants can apply for a Global Talent visa under relaxed criteria. The changed criteria are:
                </Typography>
                <ul>
                    <li>Research grants must have been awarded for at least 12 months or more (not two years or more).</li>
                    <li>Researchers must have an employment contract or hosting agreement for 12 months or more (not two years or more).</li>
                </ul>
                <Typography id="modal-modal-description">
                    Applicants who are already in the UK can switch into this route from any other visa category.
                </Typography>
            </Box>,
            ru: <Box>
                <Typography id="modal-modal-description">
                До 31 декабря 2021, академики и исследователи желающие работать на исследовательских грантах, имеющих отношение к COVID-19 могут подать на визу талантов по облегченному режиму, который состоит в следующем:
                </Typography>
                <ul>
                    <li>Исследовательские гранты должны быть как минимум на 12 или больше месяцев (вместо двух лет)</li>
                    <li>Исследователи должны иметь договор на работу или посещение на 12 или больше месяцев (вместо двух лет)</li>
                </ul>
                <Typography id="modal-modal-description">
                    Если подающийся уже находится на территории Великобритании, он может переключиться на визу талантов из любой другой иммиграционной категории.
                </Typography>
            </Box>
        }
    },

}

export default scienceClarifications;