import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useContext } from 'react';
import LangugaeContext from "components/LanguageContewxt";

import Container from 'components/Container';


const dictionary = {
  t1: {
    en: "The UK is looking for leading professionals",
    ru: "Великобритании необходимы профессионалы и специалисты"
  },
  t2: {
    en: "Are you a leader?",
    ru: "Вы - лидер?"
  },
  t3: {
    en: "93% application success rate",
    ru: "93% наших клиентов успешно подали заявку на визу талантов через нас"
  },
  t4: {
    en: "Start your free assessment now",
    ru: "Пройдите бесплатный тест"
  }
}


const Hero = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
      <Box marginBottom={2}>
        <Typography variant="h3" color="text.primary" sx={{ fontWeight: 700 }}>
        {dictionary.t1[lang]} {' '}
        
        </Typography>
        <Typography
          color={'primary'}
          component={'span'}
          variant="h2"
          fontWeight={700}
          sx={{
            mt: 5,
            background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`,
          }}
        >{dictionary.t2[lang]}
          {/* Global Talent Visa */}
        </Typography>
      </Box>
      
      <Button sx={{mt: 4, height: 63, width: {md: 420, xs: "auto"}}} href={"/global-talent-visa"} variant="contained" color="primary" size="large">
        <Typography variant="h5" sx={{mr: 1}}>{dictionary.t4[lang]}</Typography><ArrowForwardIcon/>
      </Button>
      <Box marginTop={3}>
        <Typography sx={{fontSize: "26px", fontWeight: 200}} component="p" color="text.secondary">
        {dictionary.t3[lang]}
        </Typography>
      </Box>
    </Box>
  );

  const RightSide = (): JSX.Element => {
    return (
      <Box
        sx={{
          height: { xs: 'auto', md: 1 },
          '& img': {
            objectFit: 'cover',
          },
          '& .lazy-load-image-loaded': {
            height: 1,
            width: 1,
          },
        }}
      >
        <Box
          component={LazyLoadImage}
          effect="blur"
          src={'https://images.unsplash.com/photo-1448906654166-444d494666b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80'}
          height={{ xs: 'auto', md: 1 }}
          maxHeight={{ xs: 300, md: 1 }}
          width={1}
          maxWidth={1}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
          minHeight={{ md: 700 }}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                    clipPath: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    },
                    shapeOutside: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Hero;
