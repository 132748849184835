import { useRouteMatch } from 'react-router-dom';
import { Box, Link, Typography } from "@mui/material";
import { alpha, useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import LangugaeContext from "components/LanguageContewxt";

const linkType = "h5"
const dictionary = {
    gtv: {
        en: "Global Talent Visa",
        ru: "Виза Талантов"
    },
    secondTitle: {
        en: "Where is your area expertise?",
        ru: "Какая из нижеприведенных категорий лучше всего описывает Вашу специальность?"
    },
    title : {
        art: {
            en: "Art and Creativity",
            ru: "Искусство и культура"
        },
        tech: {
            en: "Tech",
            ru: "Информационные технологии"
        },
        arch: {
            en: "Architecture",
            ru: "Архитектура"
        },
        social: { 
            en: "Social science and humanities",
            ru: "Cоциальные и гуманитарные науки"
        },
        science: { 
            en: "Science and engineering",
            ru: "Наука и инженерия"
        },
        film: { 
            en: "Film and television",
            ru: "Кино и телевидение"
        },
        fashion: {
            en: "Fashion",
            ru: "Индустрия моды"
        },
    }
}


function MainVisaApplication() {
    //@ts-ignore
    const { lang, setLang } = useContext(LangugaeContext);
    let { path, url } = useRouteMatch();
    const theme = useTheme();
    document.title = "Global Talent Visa | Sterling Law"
    return <Box sx={{
        textAlign: "center",
        p: 2,
        position: 'relative',
        '&::after': {
            position: 'absolute',
            content: '""',
            width: '5%',
            zIndex: 1,
            top: 0,
            left: '40%',
            height: '100%',
            backgroundSize: '18px 18px',
            backgroundImage: `radial-gradient(${alpha(theme.palette.primary.dark, 0.4)} 20%, transparent 20%)`,
            opacity: 0.2,
        },
    }}>

        <Typography
            color={'primary'}
            component={'span'}
            variant="h2"
            fontWeight={700}
            sx={{
                background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`,
            }}
        >
            {dictionary.gtv[lang]}
        </Typography>
        <Typography sx={{ mt: 7, mb: 4 }} variant="h5" component="div">
            <b>{dictionary.secondTitle[lang]}</b>
        </Typography>
        <Typography  sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1e40ee2a9fa2f856e4`}>{dictionary.title.art[lang]}</Link>
        </Typography>
        <Typography  sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620e53232c86dd3f2882b72f`}>{dictionary.title.tech[lang]}</Link>
        </Typography>
        <Typography sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1e40ee2a9fa2f856e5`}>{dictionary.title.arch[lang]}</Link>
        </Typography>
        <Typography sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1f40ee2a9fa2f856e8`}>{dictionary.title.science[lang]}</Link>
        </Typography>
        <Typography sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1f40ee2a9fa2f856e7`}>{dictionary.title.social[lang]}</Link>
        </Typography>
        <Typography sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1f40ee2a9fa2f856e9`}>{dictionary.title.film[lang]}</Link>
        </Typography>
        <Typography sx={{ my: 1 }} variant={linkType} component="div">
            <Link href={`https://tools.sterling-law.co.uk/quiz/?qid=620d0e1f40ee2a9fa2f856ea`}>{dictionary.title.fashion[lang]}</Link>
        </Typography>
    </Box>
}

export default MainVisaApplication;