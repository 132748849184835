/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';

const mock = [
  {
    number: '10K+',
    title: {
      ru: "Клиентов",
      en: "Clients"
    },
    subtitle: {
      ru: null,
      en: <span>We have had more than <br/> 10 000 clients</span>
    },
    icon: <GroupsIcon/>,
  },
  {
    number: '30+',
    title: {
      ru: "Юристов",
      en: "Specialists"
    },
    subtitle: {
      ru: null,
      en: <span>In Sterling Law there are more than 30 professionals specialising in more than 10 areas of law</span>
    },
    icon: <GroupsIcon/>,
  },
  {
    number: '10',
    title: {
      ru: 'Языков',
      en: 'Languages'
    },
    subtitle: {
      en: 'Our lawyers speak more than 10 languages, including Russian, French, Cantonese and Mandarin',
      ru: null
    },
    icon: <LanguageIcon/>,
  },
  {
    number: '6',
    title: {
      ru: 'Дней в неделю',
      en: 'Days'
    },
    subtitle: {
      en: 'We open 6 days a week',
      ru: null
    },
    icon: <DateRangeIcon/>,
  },
];

const dictionary = {
  title: {
    ru: "Sterling Law это",
    en: "Prefer to talk numbers?"
  },
  subtitle: {
    en: <Typography
    variant="h6"
    align={'center'}
    color={'text.secondary'}
    data-aos={'fade-up'}
  >
    
    So do we:
  </Typography>,
  ru: null
  }
}

const PromoNumbers = (): JSX.Element => {
    //@ts-ignore
    const { lang, setLang } = useContext(LangugaeContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {dictionary.title[lang]}
        </Typography>
        {dictionary.subtitle[lang]}
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box
                component={Card}
                padding={4}
                width={1}
                height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                variant={'outlined'}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <Box
                    component={Avatar}
                    width={50}
                    height={50}
                    marginBottom={2}
                    bgcolor={theme.palette.primary.main}
                    color={theme.palette.background.paper}
                  >
                    {item.icon}
                  </Box>
                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {item.number}
                  </Typography>
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    {item.title[lang]}
                  </Typography>
                  <Typography color="text.secondary">
                    {item.subtitle[lang]}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default PromoNumbers;
