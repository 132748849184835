import React from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'
import { Link } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import LangugaeContext from 'components/LanguageContewxt';

const mock = [
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0545-scaled.jpg',
    description:
      "Senior Partner",
    name: "Ruslan Kosarenko"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0556-scaled.jpg',
    description:
      "Partner",
    name: "Yuriy Lysenko"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0906-scaled.jpg',
    description:
      "Director, Solicitor",
    name: "Navdeep K Gill"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0972-scaled-e1627517803670.jpg',
    description:
      "Partner, solicitor",
    name: "Michael Iatsukha"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0617-scaled.jpg',
    description:
      "Solicitor & Higher Rights Advocate",
    name: "Xena Semikina"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/kuldeep-ou79qv0of3qvy4obkds0zxcixt5nepjr0td7zbi0f4.jpg',
    description:
      "Senior Solicitor Advocate",
    name: "Kuldeep Clair"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/anatoly-gakenberg-e1585923994915-scaled.jpg',
    description:
      "Consultant Solicitor",
    name: "Anatoly Gakenberg"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/sterling-portraits-24-1024x683-1.jpg',
    description:
      "Solicitor",
    name: "Edward Meade"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0222-scaled.jpg',
    description:
      "Head of Immigration",
    name: "Oksana Demyanchuk"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0459-scaled-e1628084212184.jpg',
    description:
      "Solicitor",
    name: "Taras Demchuk"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0152-scaled.jpg',
    description:
      "Solicitor",
    name: "Lilia Akkuchukova"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/07/dscf0470-scaled-e1628087520473.jpg',
    description:
      "Solicitor",
    name: "Valentina Nasipova"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0302-scaled-e1628092636568.jpg',
    description:
      "Solicitor",
    name: "Inna Semeniuk"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0340-cropped-scaled.jpg',
    description:
      "Associate",
    name: "Nozima Rakhimjonova"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/igor-new-ov98emngzyipgb9nh93tyjb46drjal7rd20ewaiisg-e1630503722670.jpg',
    description:
      "Associate",
    name: "Igor Persidskiy"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/josie-scaled-e1628694621739.jpg',
    description:
      "Immigration Lawyer",
    name: "Josephine Smith"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/nv-picture-scaled-e1628789550444.jpg',
    description:
      "Trainee Solicitor",
    name: "Nataliya Varahash"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dsc_2337-1024x683-1.jpg',
    description:
      "Trainee Solicitor",
    name: "Nadiya Pylypchuk"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dsc_5289-1-e1628781333836.jpg',
    description:
      "Intellectual Property Consultant",
    name: "Katsiaryna Pazniak"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/08/professional-photo-3.jpg',
    description:
      "Trainee Solicitor",
    name: "Sabrina Siu"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0081-scaled-e1628847945354.jpg',
    description:
      "Legal Assistant",
    name: "Michael Carter"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/08/sla-website-picture-e1629383084288.jpeg',
    description:
      "Legal Intern",
    name: "Ayat Robyn"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0727-scaled-e1628790556169.jpg',
    description:
      "Immigration Lawyer",
    name: "Evgenia Kabanova"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dsc_3934-1024x683-1.jpg',
    description:
      "Immigration Lawyer",
    name: "Jekaterina Trubina"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0846-scaled-e1628779821461.jpg',
    description:
      "Immigration Lawyer",
    name: "Aliya Rimshelis"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/4e238b09-dd57-4f8f-9ee8-43eed0c1a286-e1628857648695.jpg',
    description:
      "Immigration Lawyer",
    name: "Alexandra Mokrova"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/08/dscf0680-scaled.jpg',
    description:
      "Immigration & Compliance Manager",
    name: "Andrei Luca"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/06/dsc_2242.jpg',
    description:
      "Legal Assistant",
    name: "Dana Turmane"
  },
  {
    companyLogo:
      'http://sterling-law.co.uk/wp-content/uploads/2021/08/dscf0807-scaled-e1628850551437.jpg',
    description:
      "Business Development Manager",
    name: "Ilia Khudiakov"
  },
];

const dictionary = {
  title: {
    en: "Our team",
    ru: "Наша команда"
  },
  subtitle: {
    en: <Typography
          variant="h6"
          component="p"
          sx={{textAlign: "justify", lineHeight: "23px", mt: 1}}
          color="text.secondary"
        >
          Meet our high quality, licensed and certified immigration lawyers who are Global Talent Visa experts and who will be dealing with your application. To view our whole Sterling Law team please follow this <Link target="_blank" href="http://sterling-law.co.uk/team/">link</Link>.
        </Typography>,
    ru: ""
  },
  subtitle2: {
    en: "Global Talent caseworkers/assessors:",
    ru: "Социальные работники/эксперты по оценке Global Talent:"
  }
}

const Partners = (): JSX.Element => {
  //@ts-ignore
  const {lang, setLang}= useContext(LangugaeContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { mode } = theme.palette;
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  let slidesToShow = 1;
  if (isXs) {
    slidesToShow = 1;
  }
  if (isSm) {
    slidesToShow = 2;
  }
  if (isMd) {
    slidesToShow = 3;
  }

  return (
    <Box>
      <Box marginBottom={4} textAlign={'justify'}>

        <Typography
          variant="h4"
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {dictionary.title[lang]}
        </Typography>
        
          {dictionary.subtitle[lang]}

      </Box>
      <Grid container  spacing={2}>
        <Grid item md={3} xs={12}>
          <Box
            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box  sx={{p: 0}} height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0846-scaled-e1628779821461.jpg)`,
                      backgroundPosition: "75% center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "hidden",
                      p: 0
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Immigration Lawyer
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Aliya Rimshelis</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box
            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}

                    sx={{
                      backgroundImage: `url(https://sterling-law.co.uk/wp-content/uploads/2021/06/sterling-portraits-8-1200x800-1-1024x683.jpg)`,
                      backgroundPosition: "45% center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Immigration Lawyer
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Alexandra Mokrova</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box
            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/06/sterling-portraits-24-1024x683-1.jpg)`,
                      backgroundPosition: "22% center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Solicitor
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Edward Meade</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box
            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0081-scaled-e1628847945354.jpg)`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Legal Assistant
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Michael Carter</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }} spacing={2}>
        
        <Grid item md={3} xs={12}>
          <Box

            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0222-scaled.jpg)`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Head of Immigration
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Oksana Demyanchuk</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box

            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/06/dscf0545-scaled.jpg)`,
                      backgroundPosition: "43% center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "200%",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Senior Partner
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Ruslan Kosarenko</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box

            data-aos={'fade-up'}
            component={'div'}
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out'
            }}
          >
            <Box
              component={Box}
              width={1}
              height={1}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                sx={{p: 0}}
              >
                <Box height={"300px"} marginBottom={2}>
                  <Box
                    component="div"
                    width={1}
                    height={1}
                    sx={{
                      backgroundImage: `url(http://sterling-law.co.uk/wp-content/uploads/2021/08/sla-website-picture-e1629383084288.jpeg)`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "190%",
                      overflow: "hidden"
                    }}
                  />
                </Box>
                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  Immigration Lawyer
                </Typography>
                <Typography
                  align={'left'}
                  variant={'h6'}

                >
                  <b>Ayat Robyn</b>
                </Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      
    </Box>
  );
};

export default Partners;
