import { Box, Typography } from "@mui/material";

interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}

const fashionClarifications: Questions = {
    1: {
        options: {},
        clarification: {
            en: <Box>
                <Typography component="p">
                    Such as online or in print, such as features, articles and/or reviews from national publications or broadcasting companies. 
                    These must be from independent sources, and social media articles must be from prominent bloggers or key opinion leaders. 
                    Event listings or advertisements are not acceptable.
                </Typography>
            </Box>,
            ru: <Box>
                <Typography component="p">
                    Публикации могут быть как в интернете, так и в печатных изданиях. 
                    Например, могут подойти упоминания, статьи и/или обзоры в национальных изданиях или телевизионные обзоры. 
                    Источники должны быть независимыми и любые публикации в социальных сетях должны быть от лица известных блоггеров или авторитетных лиц в индустрии. 
                    Расписание событий или реклама не могут быть приняты для удовлетворения данного критерия.
                </Typography>
            </Box>
        }
    },
    2: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                <p>Nationally refers to media outlets recognized in your country of residence (one country)</p>
                <p>Internationally refers to media outlets with international recognition and exposure</p>
            </Typography>,
            ru: <Typography id="modal-modal-description">
                <p>Ответ «на национальном уровне» подразумевает огласку в средствах массовой информации в стране проживания (и только в этой стране)</p>
                <p>Ответ «на международном уровне» подразумевает огласку в средствах массовой информации имеющих международное признание и известность</p>
            </Typography>
        }
    },
    3: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }


    },
    4: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                For example, The Fashion Awards, Council of Fashion Designers of America (CFDA) Fashion Awards, Elle Style Award, GQ Award, LVMH Prize, ANDAM Prize, Hyères Prize or another relevant major award or nomination. Awards must be awards of excellence, rather than monetary awards such as grants and bursaries.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Примеры подходящих премий:
                <ul>
                    <li>Премия Fashion Awards</li>
                    <li>Премия Американского Совета Модных Дизайнеров (CDFA)</li>
                    <li>Премия Elle Style</li>
                    <li>Премия GQ</li>
                    <li>Премия LVMH</li>
                    <li>Премия ANDAM</li>
                    <li>Премия Hyères</li>
                    <li>Другая известная награда или номинация</li>

                </ul>
                Награды должны быть наградами за выдающиеся достижения, а не денежными вознаграждениями, такими как гранты и стипендии.
            </Typography>
        }
    },
    5: {
        options: {},
        clarification: { en: null, ru: null }
    },
    6: {
        options: {},
        clarification: { en: null, ru: null }
    },
    7: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                In contexts which are recognised as internationally significant in your field, or evidence of extensive international distribution and sales of your collections.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Такие показы и выставки должны иметь значимый международный статус в области моды или служить свидетельством широкого международного распространения и продаж Ваших коллекций.
            </Typography>
        }
    },
    8: {
        options: {},
        clarification: { en: null, ru: null }
    },
    9: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                Distribution and sales through internationally renowned retailers and boutiques, or through your own physical retail outlets or ecommerce platform.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Распространение и продажи могут быть как через ритейлеров и бутики, имеющие международное признание, так и через Ваши собственные торговые точки или электронную торговую платформу.
            </Typography>
        }
    },
    10: {
        options: {},
        clarification: {
            en: <Typography id="modal-modal-description">
                For example, internationally renowned fashion designers, fashion media, retailers, brands, Graduate Fashion Week - of an exceptional graduating collection.
            </Typography>,
            ru: <Typography id="modal-modal-description">
                Например, дизайнерами международного уровня, средствами массовой информации модной индустрии, ритейлерами, брендами, на Graduate Fashion Week (в случае уникальной и исключительной коллекции, являющейся частью выпускной работы)
            </Typography>
        }
    },
}

export default fashionClarifications;
