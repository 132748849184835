import { Box, Typography } from "@mui/material";

interface Questions {
    [key: number]: {
        clarification: {
            en: JSX.Element | null,
            ru: JSX.Element | null
        },
        options?: Object
    }
}

const filmClarifications: Questions = {
    1: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    2: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    3: {
        options: {},
        clarification: {
            en: null,
            ru: null
        }
    },
    4: {
        options: {},
        clarification: {
            en: <Box>
                <Typography>
                    International distribution sales and recognition may include evidence of cinema ticket sale, theatre ticket sales, international advertisement, newspaper clippings, etc
                </Typography>
            </Box>,
            ru: <Box>
                <Typography>
                    В качестве доказательств можно предоставить данные о продаже билетов на показы фильма в театрах или кинотеатрах, о рекламе на международном уровне, вырезки из газет и тому подобное.
                </Typography>
            </Box>
        }
    },
    5: {
        options: {},
        clarification: {
            en: <Box>
                <Typography>
                    Where the production(s) are films, the films must have had a theatrical release. Please click the following link for the list of notable industry recognition awards
                    <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography>
                    Если постановки, за которые Вы получили подобные премии являются кинопостановками, для удовлетворения этого критерия у таких постановок должен был быть театральный релиз. Пожалуйста, ознакомьтесь со списком подходящих премий по ссылке
                    <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>
        }
    },
    6: {
        options: {},
        clarification: {
            en: <Box>
                <Typography>
                    Where the production(s) are films, the films must have had a theatrical release. Please click the following link for the list of notable industry recognition awards
                <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography>
                    Если постановки, за которые Вы получили подобные премии являются кинопостановками, для удовлетворения этого критерия у таких постановок должен был быть театральный релиз. Пожалуйста, ознакомьтесь со списком подходящих премий по ссылке
                    <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>
        }
    },
    7: {
        options: {},
        clarification: {
            en: <Box>
                <Typography>
                    Where the production(s) are films, the films must have had a theatrical release. Please click the following link for the list of notable industry recognition awards
                <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography>
                    Если постановки, за которые Вы получили подобные премии являются кинопостановками, для удовлетворения этого критерия у таких постановок должен был быть театральный релиз. Пожалуйста, ознакомьтесь со списком подходящих премий по ссылке
                    <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>
        }
    },
    8: {
        options: {},
        clarification: {
            en: <Box>
                <Typography>
                    Where the production(s) are films, the films must have had a theatrical release. Please click the following link for the list of notable industry recognition awards
                <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>,
            ru: <Box>
                <Typography>
                    Если постановки, за которые Вы получили подобные премии являются кинопостановками, для удовлетворения этого критерия у таких постановок должен был быть театральный релиз. Пожалуйста, ознакомьтесь со списком подходящих премий по ссылке
                    <a target="_blank" href="https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html"> https://www.pact.co.uk/talent-visas/notable-industry-recognition-awards-list.html</a>
                </Typography>
            </Box>
        }
    }
}

export default filmClarifications;
