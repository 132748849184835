import {
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
    TextField,
    Typography,
    Card,
    Modal,
    LinearProgress,
    Grid,
    Link
} from "@mui/material";
import VideoB from "./AssesmentHeader/AssessmentHeader";
import { SentimentDissatisfied } from '@mui/icons-material';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';
import { Info, HelpOutline } from '@mui/icons-material';
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import artClarifications from './clarifications/ArtClarification';
import techClarifications from './clarifications/TechClarification';
import archClarifications from './clarifications/ArchClarification';
import scienceClarifications from './clarifications/ScienceClarifications';
import socialClarifications from './clarifications/SocialClarifications';
import filmClarifications from './clarifications/FilmClarification';
import fashionClarification from './clarifications/FashionClarification'
import calculatePoints from '../../helpers';
import resultTypes from './resultTypes';
import consts from "../../consts";

const title = {
    art: {
        en: "Art and Creativity",
        ru: "Искусство и культура"
    },
    tech: {
        en: "Tech",
        ru: "Информационные технологии"
    },
    arch: {
        en: "Architecture",
        ru: "Архитектура"
    },
    social: {
        en: "Social science and humanities",
        ru: "Cоциальные и гуманитарные науки"
    },
    science: {
        en: "Science and engineering",
        ru: "Наука и инженерия"
    },
    film: {
        en: "Film and television",
        ru: "Кино и телевидение"
    },
    fashion: {
        en: "Fashion",
        ru: "Индустрия моды"
    },
}


// We are using dublicate terms in dictionaries for flexible changes
const dictionary = {
    checkInfoLabel: {
        ru: "Дополнительная информация",
        en: "Please click for further information"
    },
    continueLabel: {
        en: "Continue",
        ru: "Продолжить"
    },
    goBackLabel: {
        en: "Go Back",
        ru: "Назад"
    },
    gtv: {
        en: "Global Talent Visa",
        ru: "Виза талантов"
    },
    mandatoryNotification: {
        en: "This question is mandatory",
        ru: "Дайте ответ на вопрос"
    },
    detailsTitle: {
        en: "Please provide your details:",
        ru: "Предоставте, пожалуйста, контактные данные:"
    },
    fullNameLabel: {
        en: "Enter full name",
        ru: "Ф.И.О."
    },
    emailLabel: {
        en: "Enter email",
        ru: "E-mail"
    },
    phoneLabel: {
        en: "Enter phone",
        ru: "Телефон"
    },
    nationalityLabel: {
        en: "Enter nationality",
        ru: "Национальность"
    },
    countryLabel: {
        en: "Enter country",
        ru: "Страна проживания"
    },
    terms1: {
        en: "*By clicking 'Submit' button you agree with our",
        ru: "*Нажимаю кнопку 'Отправить' вы соглашаетесь с"
    },
    terms2: {
        en: "company terms & conditions",
        ru: "условиями компании"
    },
    ineligible: {
        en: "Sorry looks like you are ineligible to continue. Please start again to choose a different profession or schedule a Consultation with our immigration lawyers now.",
        ru: "Похоже, что на данный момент у вас не достаточно компетенций, чтобы продолжить опрос по этому направлению. Пожалуйста, забронируйте консультацию с нашими иммиграционными юристами, либо начинте сначала."
    },
    call: {
        en: "Book consultation",
        ru: "Забронировать консультацию"
    },
    startAgain: {
        en: "Start again",
        ru: "Начать заново"
    }
}


const clarificationMap = {
    art: artClarifications,
    tech: techClarifications,
    arch: archClarifications,
    science: scienceClarifications,
    social: socialClarifications,
    film: filmClarifications,
    fashion: fashionClarification
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "calc(100vh - 210px)",
    overflowY: "auto" as "auto",
    textAlign: "justify" as "justify"
};

export default function VisaApplication(props: { type: string }) {
    // @ts-ignore
    const { lang, setLang } = useContext(LangugaeContext);
    const [stopView, setStopView] = useState('none')
    const [showStopAfterContinue, setShowStopAfterContinue] = useState(false);

    // @ts-ignore
    document.title = title[props.type][lang] + " | Global Talent Visa | Sterling Law"
    const [clarifications] = useState(clarificationMap[props.type]);
    const [validationRawSchema, setValidationRawSchema] = useState({
        fullname: Yup.string().required('Fullname is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        phone: Yup.string()
            .required('Phone is required'),
        nationality: Yup.string().required('Nationality is required'),
        country: Yup.string().required('Country is required'),
    });

    // @ts-ignore

    const [currentQuestion, setQuestion] = useState<any>(undefined);
    const [assessment, setAssessment] = useState(undefined);
    const [questions, setQuestions] = useState({})
    const [maximum, setMaximum] = useState(0)
    useEffect(() => {
        axios.get(`${consts.API_URL}/dictionary/global-talent-visa-questions/${props.type}`)
            .then((response) => {
                setQuestions(response.data.questions);
                //@ts-ignore

                setAssessment(response.data.assessment);
                // @ts-ignore
                setQuestion(response.data.questions[1]);
                setMaximum((Object.keys(response.data.questions).length));

                for (const key of Object.keys(questions)) {
                    // @ts-ignore
                    validationRawSchema[questions[key].id] = Yup.string().required('Is required')
                };

                setValidationRawSchema(validationRawSchema)
                // return
            });

    }, []);

    let validationSchema = Yup.object().shape(validationRawSchema);
    const [testView, setTestView] = useState('block'); //block
    const [progressView, setProgressView] = useState('flex'); //flex
    const [formView, setFormView] = useState('none'); // none
    const [finishView, setFinishView] = useState('none'); // none
    const [resultType, setResultType] = useState(2); // 2
    const [points, setPoints] = useState(2);
    const [values, setValues] = useState<any>({})

    const [errorLabelVisibility, setErrorLabelVisibility] = useState('none');

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<JSX.Element>(<Box></Box>);
    const handleOpen = (content: JSX.Element) => {
        setContent(content);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        let new_data = Object.assign(data, values);
        let calculatedPoints = calculatePoints(new_data, questions);

        setTestView('none')
        setFinishView('block')
        setFormView('none');
        setPoints(calculatedPoints.points)

        // TODO: move to backend

        // @ts-ignore
        if (calculatedPoints.points >= assessment.mid[0] && calculatedPoints.points <= assessment.mid[1]) {
            setResultType(1);
            // @ts-ignore
        } else if (calculatedPoints.points >= assessment.max[0]) {
            setResultType(0);
        }

        axios.post(`${consts.API_URL}/application/`,
            {
                "user": {
                    "fullname": data.fullname,
                    "email": data.email,
                    "nationality": data.nationality,
                    "country": data.country,
                    "phone": data.phone
                },
                "type": "gt",
                "direction": props.type,
                "points": calculatedPoints.points,
                "answers": calculatedPoints.answers,
                "lang": lang
            }
        )
            .then((response) => { console.log(response.data) });
    };

    const onBack = (q: any) => {
        setErrorLabelVisibility("none");

        //@ts-ignore
        let nextId = currentQuestion?.number - 1;
        if (nextId in questions) {
            // @ts-ignore
            setQuestion(questions[nextId])
        } else {
            return
        }
    }


    const onContinue = (q: any) => {
        // @ts-ignore
        if (!values[q]) {
            setErrorLabelVisibility("block")
            return
        }

        if (showStopAfterContinue) {
            setStopView('flex')
            setTestView('none')
            setProgressView('none')
            return
        }
        setErrorLabelVisibility("none")
        // @ts-ignore
        let nextId = currentQuestion?.number + 1;
        if (nextId in questions) {
            // @ts-ignore
            setQuestion(questions[currentQuestion?.number + 1])
        } else {
            setFormView('block');
            setTestView('none');
            setProgressView('none')
        }
    }

    let test = <Grid container alignContent="center">
        <Grid item xs={12} md={6} sx={{ m: "auto" }}>
            <Box sx={{ py: 3, mb: 0 }}><Box sx={{
                alignContent: 'center',
                textAlign: "left",
                m: 'auto',
            }}>
          
          
                <Box sx={{ textAlign: 'center', pb: 2 }}>

                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                      
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                     
                    </Typography>

                
                </Box>
                <Box data-aos="fade-up" sx={{ display: progressView, alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={
                            // @ts-ignore
                            currentQuestion?.number / maximum * 100} />
                    </Box>
                    <Box sx={{ minWidth: 55 }}>
                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                            currentQuestion?.number,
                        )} / ${maximum}`}</Typography>
                    </Box>
                </Box>
                <Box data-aos="fade-up">
                    <Card sx={{
                        px: 3,
                        py: 2,
                        mt: 2,
                        backgroundColor: "white",
                        borderRadius: 2,
                        display: testView
                    }}>
                        <Box sx={{ mb: 2 }}>
                            <FormLabel
                                focused={errors[currentQuestion?.id] ? true : false}
                                error={errors[currentQuestion?.id] ? true : false}
                                sx={{ color: '#3c3c3c' }}
                            >
                                <Typography><b>{currentQuestion?.title[lang].split(">br<")[0]}</b></Typography>
                                <Typography color="color.primary" sx={{ textAlign: 'justify', color: '#a0a0a0' }}><b>{currentQuestion?.title[lang].split(">br<")[1]}</b></Typography>

                            </FormLabel>



                        </Box>
                        <RadioGroup
                            aria-label="la"
                            name="radio-buttons-group"
                        >
                            {currentQuestion ? Object.values(currentQuestion?.options).map((option: any) => {

                                return <Box sx={{ display: "flex" }}><FormControlLabel
                                    onClick={() => {
                                        //@ts-ignore
                                        let newValues = Object.assign({}, values);

                                        newValues[currentQuestion?.id] = option?.value + "_" + currentQuestion?.id;
                                        setShowStopAfterContinue(false)
                                        if (option?.stop) {
                                            setShowStopAfterContinue(true)
                                        }
                                        setValues(newValues);
                                    }}
                                    value={option?.value + "_" + currentQuestion?.id}
                                    name={`radio-buttons-${currentQuestion?.id}`}
                                    sx={{ width: "100%" }}
                                    control={<Radio />}
                                    checked={values[currentQuestion?.id] === option?.value + "_" + currentQuestion?.id ? true : false}
                                    label={<Typography>{option?.title[lang]}</Typography>}
                                /> {Object.keys(clarifications[currentQuestion?.number]?.options).includes(option?.value) ? <HelpOutline sx={{
                                    my: "auto",
                                    color: "#3c3c3c",
                                    ":hover": {
                                        color: "#1976d2",
                                        cursor: "pointer"
                                    },
                                }}
                                    onClick={() => { handleOpen(<Box>{clarifications[currentQuestion?.number]?.options[option?.value][lang]}</Box>) }} /> : false
                                    }</Box>

                            }) : false}
                        </RadioGroup>
                        <Box sx={{display: {md: "none", xs: "block"}, mt: 3}}>
                            <Typography sx={{ my: "auto", width: "50%", color: "red", display: errorLabelVisibility}}>
                                {dictionary.mandatoryNotification[lang]}*
                            </Typography>
                        </Box>
                        
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            mt: 3,
                            width: "100%"
                        }}>
                            <Box sx={{ display: "flex", ml: "auto" }}>
                                <Button
                                    variant="outlined"
                                    sx={{ display: (currentQuestion?.number - 1 in questions) ? "block" : "none", mr: 1 }}
                                    onClick={function () { onBack(currentQuestion?.id) }}
                                >
                                    {dictionary.goBackLabel[lang]}
                                </Button>
                                <Button
                                    onClick={function () { onContinue(currentQuestion?.id) }}
                                    variant="contained">
                                    {dictionary.continueLabel[lang]}
                                </Button>
                            </Box>
                            <Box sx={{display: {md: "flex", xs: "none"}, width: "50%",}}>
                            <Typography sx={{ my: "auto",color: "red", display: errorLabelVisibility, ml: clarifications[currentQuestion?.number]?.clarification[lang] ? 3 : 0 }}>
                                {dictionary.mandatoryNotification[lang]}*
                            </Typography>
                        </Box>
                            {clarifications[currentQuestion?.number]?.clarification[lang] ?

                                <Box sx={{ display: "flex" }}>

                                    <Button
                                        sx={{
                                            p: 0,
                                            ":hover": {
                                                color: "#1976d2 !important",
                                                cursor: "pointer",
                                                backgroundColor: "#fff"
                                            }
                                        }}
                                        onClick={() => {
                                            handleOpen(<Box>{clarifications[currentQuestion?.number]?.clarification[lang]}</Box>)
                                        }}
                                    >

                                        <Info sx={{
                                            my: "auto",
                                            color: "#3c3c3c",

                                        }} />
                                        <Box sx={{ mr: "auto", mx: "3px", color: "#a0a0a0", fontSize: 12 }}>
                                            {dictionary.checkInfoLabel[lang]}
                                        </Box>
                                    </Button>
                                </Box>
                                : false}


                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="Modal-Modal-title"
                                aria-describedby="Modal-Modal-description"
                            >
                                <Grid container>
                                    <Grid item xs={12} md={6} sx={{ m: "auto" }}>
                                        <Card sx={style}>
                                            {content}
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Modal>
                        </Box>
                    </Card>

                    
                </Box>
                <Card sx={{
                    px: 3,
                    py: 2,
                    mt: 2,
                    backgroundColor: "white",
                    borderRadius: 2,
                    display: stopView
                }}>

                    <Box sx={{ textAlign: 'left' }}>
                        <Typography sx={{ textAlign: "left", mt: 2 }}>
                        {dictionary.ineligible[lang]}
                        </Typography>
                        <Box sx={{display: 'flex'}}>
                        <SentimentDissatisfied sx={{ mx: 1, my: "auto" }} color="error" />
                        <Button
                            variant="outlined"
                            href="/global-talent-visa/"
                            sx={{
                                mt: 3,
                                ml: 'auto'
                            }}
                        >
                            {dictionary.startAgain[lang]}
                        </Button>
                        <Button
                            variant="contained"
                            target="_blank"
                            href="https://calendly.com/sterling-law/immigration-talent-visa"
                            sx={{
                                mt: 3,
                                ml: 2
                            }}
                        >
                            {dictionary.call[lang]}
                        </Button>
                        </Box>
                    </Box>

                </Card>
                <Box>
                    {/* <FormControl variant="standard" sx={{ width: '60%', mb: 5, m: "auto" }}> */}
                    <Box sx={{ mb: 3, px: 5, py: 3, display: formView, margin: 'auto' }}>
                        <Box>
                            <Box sx={{ mb: 1, color: '#3c3c3c' }}>

                                <b>{dictionary.detailsTitle[lang]}</b>
                            </Box>
                            <Typography variant={'subtitle2'}>
                                {dictionary.fullNameLabel[lang]}
                            </Typography>
                            <TextField
                                required
                                id="fullname"
                                label={dictionary.fullNameLabel[lang]}
                                sx={{ width: '100%' }}
                                margin="dense"
                                // @ts-ignore
                                {...register('fullname')}
                                error={errors.fullname ? true : false}
                                variant="outlined"
                            />

                            <Typography variant={'subtitle2'} sx={{ mt: 2 }}>
                                {dictionary.emailLabel[lang]}
                            </Typography>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label={dictionary.emailLabel[lang]}
                                sx={{ width: '100%' }}
                                margin="dense"
                                { //@ts-ignore
                                ...register('email')}
                                error={errors.email ? true : false}
                                variant="outlined"
                            />
                            <Typography variant={'subtitle2'} sx={{ mt: 2 }}>
                                {dictionary.phoneLabel[lang]}
                            </Typography>
                            <TextField
                                required
                                id="phone"
                                name="phone"
                                sx={{ width: '100%' }}
                                label={dictionary.phoneLabel[lang]}
                                margin="dense"
                                { //@ts-ignore
                                ...register('phone')}
                                error={errors.phone ? true : false}
                                variant="outlined"
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={'subtitle2'} sx={{ mt: 2 }}>
                                        {dictionary.nationalityLabel[lang]}
                                    </Typography>
                                    <TextField
                                        required
                                        id="nationality"
                                        name="nationality"
                                        sx={{ width: '100%' }}
                                        label={dictionary.nationalityLabel[lang]}
                                        margin="dense"
                                        { //@ts-ignore
                                        ...register('nationality')}
                                        error={errors.email ? true : false}
                                        variant="outlined"
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={'subtitle2'} sx={{ mt: 2 }}>
                                        {dictionary.countryLabel[lang]}
                                    </Typography>
                                    <TextField
                                        required
                                        id="country"
                                        name="country"
                                        sx={{ width: '100%' }}
                                        label={dictionary.countryLabel[lang]}
                                        margin="dense"
                                        { //@ts-ignore
                                        ...register('country')}
                                        error={errors.country ? true : false}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>


                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 3
                            }}>
                                <Grid container>

                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={10}
                                        justifyContent={'left'}
                                        alignItems={'center'}
                                    >
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'text.secondary'}
                                            align={'left'}
                                        >
                                            {dictionary.terms1[lang]}{' '}

                                            <Link
                                                component={'a'}
                                                color={'primary'}
                                                href={'/terms'}
                                                target={'_blank'}
                                                underline={'none'}
                                            >
                                                {dictionary.terms2[lang]}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={2} justifyContent={'center'}>
                                        <Button onClick={handleSubmit(onSubmit)} sx={{ width: "100%", my: "auto" }} variant="contained">Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                    </Box>


                    {/* </FormControl> */}
                </Box>

            </Box>
                <Grid sx={{
                    display: finishView,
                    mb: 0,

                }}>
                    <Card sx={{
                        px: 3,
                        textAlign: "left",
                        m: 'auto',
                        mt: 3,
                        mb: 0,
                        py: 1,
                        borderRadius: 2
                    }}>
                        <Box sx={{ py: 1, mb: 0 }}>
                            {resultTypes[props.type][resultType].body[lang]}
                        </Box>
                    </Card>

                </Grid>

                
            </Box>
        </Grid>
    </Grid >
    return test
}
