import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LangugaeContext from "components/LanguageContewxt";
import { useContext } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const dictionary = {
  title1: {
    en: "Any Questions?",
    ru: "Остались вопросы?"
  },
  title2: {
    en: "Schedule a call with immigration lawyer now",
    ru: "Забронируйте звонок с нашим специалистом прямо сейчас"
  },
  scheduleTitle: {
    en: "Schedule",
    ru: "Забронировать"
  }
}


const Application = (): JSX.Element => {
  //@ts-ignore
  const { lang, setLang } = useContext(LangugaeContext);
  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          sx={{
            fontWeight: 700,
          }}
        >
          {dictionary.title1[lang]}

        </Typography>
      </Box>
      <Box
        display={'flex'}
        data-aos={'fade-up'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography
          variant="h6"
          component="p"
          sx={{textAlign: "justify", lineHeight: "23px", mt: 1}}
          color="text.secondary">
            {dictionary.title2[lang]}
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Button sx={{height: 50, width: {md: 200, xs: "auto"}}} target="_blank" href="https://calendly.com/sterling-law/immigration-talent-visa" variant="contained" color="primary" size="large">
            <Typography sx={{mr: 1}}>{dictionary.scheduleTitle[lang]}</Typography><ArrowForwardIcon/>
          </Button>

        </Box>
      </Box>
    </Box>
  );
};

export default Application;
