import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button'

import Container from 'components/Container';
import { ContactCard, Content } from './components';

const CookiesPolicy = (): JSX.Element => {
  const theme = useTheme();
  document.title = "Cookies Policy | Sterling Law"
  return (

    <Container>
      <Box boxShadow={4} borderRadius={2}>
        <Box bgcolor={theme.palette.primary.main} borderRadius={2}>
          <Container paddingX={{ xs: 2, sm: 4 }}>
            <Typography
              variant={'h4'}
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              Cookies policy
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: theme.palette.common.white,
              }}
            >
              Effective <strong>1 November, 2021</strong>
            </Typography>
          </Container>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            width={1}
            marginBottom={-1}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Container
          paddingTop={'0 !important'}
          paddingX={{ xs: 2, sm: 4 }}
          position={'relative'}
          top={0}
        >
          <Box
            component={Grid}
            container
            spacing={4}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid sx={{textAlign: "justify"}}  item xs={12} md={9}>
              <Box>
                <Typography><strong>About this cookie policy</strong></Typography>
                <Typography mt={1}><span>This Cookie Policy explains what cookies are and how we use them. You should read this policy to understand what cookies are, how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used and how to control the cookie preferences. For further information on how we use, store and keep your personal data secure, see our Privacy Policy.</span></Typography>
                <Typography mt={1}><span>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</span></Typography>
                <Typography mt={1}><span>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</span></Typography>
                <Typography mt={1}><span>Your consent applies to the following domains: <a href="https://talentvisa.uk/">talentvisa.uk</a></span></Typography>
                <Typography mt={1}><span>If you would like to change or withdraw your consent, please click&nbsp; </span><span><a className="cky-banner-element"><Button>here</Button></a></span><span>&nbsp;</span></Typography>
                <Typography mt={3}><strong>What are cookies ?</strong></Typography>
                <Typography mt={1}><span>Cookies are small text files that are used to store small pieces of information. The cookies are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make the website more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</span></Typography>
                <Typography mt={3}><strong>How do we use cookies ?</strong></Typography>
                <Typography mt={1}><span>As most of the online services, our website uses cookies first-party and third-party cookies for a number of purposes. The first-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</span></Typography>
                <Typography mt={1}><span>The third-party cookies used on our websites are used mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</span></Typography>

                <Typography mt={3}><strong>What types of cookies do we use ?</strong></Typography>
                <Typography mt={1}><span>Essential: Some cookies are essential for you to be able to experience the full functionality of our site. They allow us to maintain user sessions and prevent any security threats. They do not collect or store any personal information. For example, these cookies allow you to log-in to your account and add products to your basket and checkout securely.</span></Typography>
                <Typography mt={1}><span>Statistics:&nbsp;These cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit etc. These data help us understand and analyze how well the website performs and where it needs improvement.</span></Typography>
                <Typography mt={1}><span>Marketing:&nbsp;Our website displays advertisements. These cookies are used to personalize the advertisements that we show to you so that they are meaningful to you. These cookies also help us keep track of the efficiency of these ad campaigns.</span></Typography>
                <Typography mt={1}><span>Targeting cookies: These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</span></Typography>
                <Typography mt={1}><span>The information stored in these cookies may also be used by the third-party ad providers to show you ads on other websites on the browser as well.</span></Typography>
                <Typography mt={1}><span>Functional:&nbsp;These are the cookies that help certain non-essential functionalities on our website. These functionalities include embedding content like videos or sharing contents on the website on social media platforms.</span></Typography>
                <Typography mt={1}><span>Preferences:&nbsp;These cookies help us store your settings and browsing preferences like language preferences so that you have a better and efficient experience on future visits to the website.</span></Typography>
                <Typography mt={1}><span>Please note that the following third parties may also use cookies, over which we have no control. These named third parties may include, for example, advertising networks and providers of external services like web traffic analysis services. These third party cookies are likely to be analytical cookies or performance cookies or targeting cookies: Google Analytics, Calendly.</span></Typography>
                <Typography mt={4}><strong>Cookies we are using:</strong></Typography>
                <Typography mt={2}><strong>Necessary</strong></Typography>
                <Typography mt={1}><span>Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</span></Typography>
                <Box mt={3}>
                  <table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'20%'} align="left">Cookie name</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Expiry</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width={'20%'} align="left">cookielawinfo-checkbox-necessary</TableCell>
                        <TableCell align="left">Determines whether the user has accepted the cookie consent box.</TableCell>
                        <TableCell width={'10%'} align="left">1 year</TableCell>
                      </TableRow>
                    </TableBody>
                  </table>
                </Box>
                <Typography mt={2}><strong>Functional</strong></Typography>
                <Typography mt={1}><span>Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features.</span></Typography>
                <Box mt={3}>
                  <table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'20%'} align="left">Cookie name</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Expiry</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width={'20%'} align="left">ugid</TableCell>
                        <TableCell align="left">This cookie is set by the provider Unsplash. This cookie is used for enabling the video content on the website.</TableCell>
                        <TableCell width={'10%'} align="left">1 year</TableCell>
                      </TableRow>
                    </TableBody>
                  </table>
                </Box>


                <Typography mt={3}><strong>Analytics</strong></Typography>
                <Typography mt={1}><span>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.</span></Typography>
                <Box mt={3}>
                  <table>
                    
                      <TableHead>
                        <TableRow>
                          <TableCell width={'20%'} align="left">Cookie name</TableCell>
                          <TableCell align="left">Description</TableCell>
                          <TableCell width={'10%'} align="left">Expiry</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell width={'20%'} align="left">_ga_1NS62ZMNZP</TableCell>
                          <TableCell align="left">This cookie is installed by Google Analytics.</TableCell>
                          <TableCell width={'10%'} align="left">2 year</TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell width={'20%'} align="left">_ga</TableCell>
                          <TableCell align="left">The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.</TableCell>
                          <TableCell width={'10%'} align="left">2 year</TableCell>

                        </TableRow>
                      </TableBody>
              
                  </table>
                </Box>

                <Typography mt={2}><strong>Performance</strong></Typography>
                <Typography mt={1}><span>Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.</span></Typography>
                <Typography mt={2}><strong>Advertisement</strong></Typography>
                <Typography mt={1}><span>Advertisement cookies are used to deliver visitors with customized advertisements based on the pages they visited before and analyze the effectiveness of the ad campaign.</span></Typography>

                <Typography mt={2}><strong>How can I control the cookie preferences ?</strong></Typography>
                <Typography mt={1}><span>Should you decide to change your preferences later through your browsing session, you can click on the &ldquo;Privacy &amp; Cookie Policy&rdquo; tab on your screen. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely.</span></Typography>
                <Typography mt={1}><span>In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. To find out more out more on how to manage and delete cookies, visit <a href="https://en.wikipedia.org/wiki/HTTP_cookie">wikipedia.org</a>, <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org.</a></span></Typography>
                <Typography mt={1}><span>Except for essential cookies, all cookies will expire after 1 year period.</span></Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                position={'sticky'}
                top={theme.spacing(10)}
                className={'sticky'}
              >
                <ContactCard />
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default CookiesPolicy;
