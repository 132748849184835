import LangugaeContext from "./LanguageContewxt";
import {useState} from 'react';


const LanguageProvider = ({ ...props }) => {
    const [lang, setLang] = useState(localStorage.getItem('lang'));

    const value = {
        lang,
        setLang
    }

    return <LangugaeContext.Provider {...props} value={value} />;
}

export default LanguageProvider;
