import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Partners from 'components/Partners';
import Video from 'components/Video';
import VideoB from 'components/VideoB';
import Main from 'layouts/Main';
import Container from 'components/Container';
import HowItWorks from 'components/HowItWorks';
import Prices from 'components/Prices';
import {
  About,
  AboutBottom,
  Features,
  Hero,
  Integrations,
  Jobs,
  News,
  Pricings,
  Reviews,
  Team,
  Trucking,
  PromoNumbers,
  Licence,
  Application,
} from './components';
import Services from 'components/Services';
import { Divider } from '@mui/material';


const Logistics = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        bgcolor={'alternate.main'}
        sx={{
          position: 'relative',
          '&::after': {
            position: 'absolute',
            content: '""',
            width: '30%',
            zIndex: 1,
            top: 0,
            left: '5%',
            height: '100%',
            backgroundSize: '18px 18px',
            backgroundImage: `radial-gradient(${alpha(theme.palette.primary.dark, 0.4)} 20%, transparent 20%)`,
            opacity: 0.2,
          },
        }}
      >
        <Box position={'relative'} zIndex={3}>
          <Hero />
        </Box>
      </Box>
      <Container>
        <Services />
      </Container>
      {/* <Container sx={{pb:5}}>
        <Video/>
      </Container> */}
      <VideoB />
      <About />
      <Licence />

      <Box bgcolor={'alternate.main'}>
        <Container sx={{ pb: 5 }}>
          <HowItWorks/>
        </Container>
      </Box>
      <Box bgcolor={'#0c133e'} sx={{color: "common.white"}}>
        <Container sx={{ pb: 5 }}>
          <Prices/>
        </Container>
      </Box>
      <Container sx={{ pb: 5 }}>
        <Partners />
      </Container>
      <Box bgcolor={'alternate.main'}>
        <Container >
          <PromoNumbers />
        </Container>
      </Box>

      <Divider />
      <Container sx={{ pb: 5 }}>
        <Application />
      </Container>
    </Box>
  );
};

export default Logistics;
