import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Select, MenuItem} from '@mui/material';
import LanguageContext from '../../../../components/LanguageContewxt';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    landings: Array<PageItem>;
    company: Array<PageItem>;
    account: Array<PageItem>;
    secondary: Array<PageItem>;
    blog: Array<PageItem>;
    portfolio: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const dict = {
  home: {
    en: "Home",
    ru: "Главная"
  },
  gtv: {
    en: "Global Talent Visa",
    ru: "Виза талантов"
  }
};



const Topbar = ({ onSidebarOpen, pages, colorInvert = false}: Props): JSX.Element => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  //@ts-ignore
  const {lang, setLang}= useContext(LanguageContext);

  const handleChangeLang = async (event) => {
    //@ts-ignore
    setLang(event.target.value);
    await localStorage.setItem("lang", event.target.value);
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Sterling Law"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={
            (mode === 'light' && !colorInvert)
              ? 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
              : 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
          }
          height={1}
          width={1}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Button
          size={'large'}
          component={'a'}
          href={"/"}
          sx={{
            width: 100,
            justifyContent: 'center',
            color:
              activeLink === "/"
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            fontWeight: activeLink === "/" ? 600 : 400,
          }}
        >
          {
          //@ts-ignore
          dict.home[lang]
          }
        </Button>
        <Button
          size={'large'}
          component={'a'}
          href={"/global-talent-visa"}

          sx={{
            width: 200,
            justifyContent: 'center',
            color:
              activeLink.includes("global-talent-visa")
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            fontWeight: activeLink.includes("global-talent-visa") ? 600 : 400,
          }}
        >{
          //@ts-ignore
          dict.gtv[lang]
        }
        </Button>
        <Button
          size={'large'}
          component={'a'}
          href={"/faq"}

          sx={{
            width: 20,
            justifyContent: 'center',
            color:
              activeLink.includes("faq")
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            fontWeight: activeLink.includes("faq") ? 600 : 400,
          }}
        >FAQ</Button>
        
      </Box>
      <Select
          sx={{
            ml: 3, 
            ":before": {
              border: 0
            },
            ":after": {
              border: 0,
              backgroundColor: "transparent !important"
            },
            ":focus": {
              border: 0,
              backgroundColor: "transparent"
            },
          }}
          id="demo-simple-select"
          value={//@ts-ignore
            lang}
          onChange={handleChangeLang}
          variant="standard"
        >
          <MenuItem value={"ru"}>🇷🇺 Русский</MenuItem>
          <MenuItem value={"en"}>🇬🇧 English</MenuItem>
        </Select>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
