import { Box } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import VisaApplication from './VisaApplication/VisaApplication';
import MainVisaApplication from './VisaApplication/MainVisaApplication';
import AssessmentHeader from './VisaApplication/AssesmentHeader';

const directions = [
    'art',
    'tech',
    'arch',
    'film',
    'science',
    'social',
    'fashion'
]

export default function GlobalTalentVisa() {
    let { path } = useRouteMatch();
    return <Switch>
        <Route exact path={path}>
            <Box sx={{pt: 7}}>
                <MainVisaApplication/>
            </Box>
        </Route>

        {
            directions.map((d) => {
                return (
                    <Route path={`${path}/apply/${d}`}>
                        <AssessmentHeader direction={d}/>
                        <VisaApplication type={d} />
                    </Route>
                )    
            })
        }
    </Switch>
}