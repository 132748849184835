import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import consts from 'components/consts';
import LangugaeContext from 'components/LanguageContewxt';


const headerDict = {
  fashion: {
    headerVideo: {
      url:  "https://www.youtube.com/embed/xnlbVj7DM9c",
      show: {
        ru: true,
        en: false
      }
    },
    title: {
      en: "Fashion",
      ru: "Индустрия моды"
    }
  },
  film: {
    headerVideo: {
      url:  "",
      show: {
        ru: false,
        en: false
      }
    },
    title: {
      en: "Film and television",
      ru: "Кино и телевидение"
    }
  },
  arch: {
    headerVideo: {
      url:  "https://www.youtube.com/embed/plEOtNYpYwM",
      show: {
        ru: true,
        en: false
      }
    },
    title: {
      en: "Architecture",
      ru: "Архитектура"
    }
  },
  tech: {
    headerVideo: {
      url:  "",
      show: {
        ru: false,
        en: false
      }
    },
    title: {
      en: "Tech",
      ru: "Информационные технологии"
    }
  },
  art: {
    headerVideo: {
      url:  "",
      show: {
        ru: false,
        en: false
      }
    },
    title: {
      en: "Art and Creativity",
      ru: "Искусство и культура"
    }
  },
  social: {
    headerVideo: {
      url:  "",
      show: {
        ru: false,
        en: false
      }
    },
    title: {
      en: "Social science and humanities",
      ru: "Cоциальные и гуманитарные науки"
    }
  },
  science: {
    headerVideo: {
      url:  "",
      show: {
        ru: false,
        en: false
      }
    },
    title: {
      en: "Science and engineering",
      ru: "Наука и инженерия"
    }
  }
}

interface Props {
  direction: string
}


const AssessmentHeader = ({direction}: Props): JSX.Element => {
  //@ts-ignore
  const { lang } = useContext(LangugaeContext);
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage:
          'url(https://images.unsplash.com/photo-1490642914619-7955a3fd483c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2093&q=80)',
        backgroundPosition: 'center center',
        p: 0,
        backgroundSize: "cover",
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: '#11092d',
          opacity: 0.7,
          zIndex: 1,
        },
      }}
    >
        <Grid
          container
          zIndex={3}
          alignContent="center"
          position={'relative'}
          spacing={{ md: 0, xs: 0 }}
          sx={{
            margin: '0 auto',
            py: 2,
            justifyContent: 'center'
          }}
        >
          <Grid item md={3} xs={12} sx={{
            display: 'flex',
            minHeight: 120,
            p: {xs: 2, md: 0},
          }}>
            <Box data-aos={{md: "fade-down", xs: false}} sx={{ mt: 'auto', p: 0 }}>
              <Typography sx={{ color: 'common.white' }} variant="subtitle1" component="div">
                {headerDict[direction]?.title[lang]}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  color: 'common.white',

                }}
              >
                {consts.dict.gtv[lang]}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} xs={12} sx={{
            p: {xs: 2, md: 0}, 
          }}>
            <Box sx={{
              display: headerDict[direction]?.headerVideo.show[lang] ? "flex" : "none",
              height: 200, p: 0 
            }}>
              <iframe width="100%" height="100%" src={headerDict[direction]?.headerVideo.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>
          </Grid>
        </Grid>


    </Box>
  );
};

export default AssessmentHeader;
