import React, {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import LanguageContext from 'components/LanguageContewxt';
import NavItem from './components/NavItem';
import { Select, MenuItem } from '@mui/material';

interface Props {
  pages: {
    landings: Array<PageItem>;
    company: Array<PageItem>;
    account: Array<PageItem>;
    secondary: Array<PageItem>;
    blog: Array<PageItem>;
    portfolio: Array<PageItem>;
  };
}

const dict = {
  home: {
    en: "Home",
    ru: "Главная"
  },
  gtv: {
    en: "Global Talent Visa",
    ru: "Виза талантов"
  }
};

const SidebarNav = ({ pages }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const [activeLink, setActiveLink] = useState('');

  //@ts-ignore
  const {lang, setLang}= useContext(LanguageContext);
  
  const handleChangeLang = async (event) => {
    //@ts-ignore
    setLang(event.target.value);
    await localStorage.setItem("lang", event.target.value);
  };

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;
  
  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Sterling Law"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
                : 'http://sterling-law.co.uk/wp-content/uploads/2021/06/logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
      <Button
              size={'large'}
              component={'a'}
              href={"/"}
              sx={{
                width: 100,
                justifyContent: 'left',
                color:
                  activeLink === "/"
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                fontWeight: activeLink === "/" ? 600 : 400,
              }}
              >{dict.home[lang]}</Button>
        <Button
              size={'large'}
              component={'a'}
              href={"/global-talent-visa"}
         
              sx={{
                width: 200,
                justifyContent: 'left',
                color:
                  activeLink.includes("global-talent-visa")
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                fontWeight:  activeLink.includes("global-talent-visa") ? 600 : 400,
              }}
              >{dict.gtv[lang]}</Button>
      </Box>
    </Box>
  );
};

export default SidebarNav;
